<template>
  <div ref="fileform" class="file-form">
    <div v-if="isFileSelected">
        {{ value.name }}
    </div>
    <div v-else>
      <label>
      <svg-icon class="icon-svg" type="mdi" :path="account"></svg-icon>
        Déposez les documents ici
        <input
          type="file"
          @change="onFileChange"
          id="file"
          style="display:none"
        />
      </label>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiTrayArrowUp } from '@mdi/js';

const eventDrags = [
  "drag",
  "dragstart",
  "dragend",
  "dragover",
  "dragenter",
  "dragleave",
  "drop"
];

export default {
    components: {
    SvgIcon,
  },
  data() {
    return {
        account: mdiTrayArrowUp,
      }
  },

  props: {
    value: {
      type: Object,
      default() {
        return {
          path: null
        };
      }
    }
  },
  mounted() {
    if (this.draggable()) {
      eventDrags.forEach(evt => {
        this.$refs.fileform.addEventListener(
          evt,
          e => {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      });

      this.$refs.fileform.addEventListener("drop", e => {
        let file = e.dataTransfer.files[0];
        this.$emit("input", file);
      });
    }
  },
  methods: {
    draggable() {
      let div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    onFileChange(e) {
        console.log(e.target)
        console.log(e.dataTransfer)
        console.log(e)
      let file = e.target.files[0] || e.dataTransfer.files[0];
      this.$emit("input", file);
      //this.value.path = e.target.files[0] || e.dataTransfer.files[0];
      //this.preview();
    },
    removeFile() {
      this.$emit("input", null);
    }
  },
  computed: {
    isFileSelected() {
      return Object.keys(this.value).length === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.ui.label > .close.icon {
  cursor: pointer;
}
img.preview {
  width: 100%;
  height: 400px;
}
div.ui.left.floated.tiny.image {
  padding-right: 1px;
  padding-top: 1px;
  width: 100%;
}

.file-form{
  .icon-svg{
    color: $color-primary;
    margin-right: 1rem;
  }
  label{
    display: flex;
    align-items: center;
  }
  width: 100%;
  margin: 1rem 0rem;
  padding: 1rem 0rem;
  border: 1px dashed $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary
}
</style>
