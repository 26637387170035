<template>
  <div
    class="second-div-applies"
  >
    <slot name="informationsJob">
      <div class="div-job">
        <span v-if="job == 1">CDP</span>
        <span v-if="job == 2">UX/UI</span>
        <span v-if="job == 3">PO</span>
      </div>
      <div class="div-name-intervention">
        <div>
          <span>{{ name }} {{ last_name }}</span>

          <div class="intervention-title freelance" v-if="intervention == 1">
            FREELANCE
          </div>
          <div class="intervention-title salariat" v-if="intervention == 2">
            SALARIAT
          </div>
          <div class="intervention-title alternance" v-if="intervention == 3">
            ALTERNANCE
          </div>
        </div>
        <slot name="svg"> </slot>
      </div>
      <div class="div-date">
        <span> {{ time_month }} </span>
        <span> {{ time_year }} </span>
      </div>
    </slot>
     <!--  <div class="dropdown is-right is-active">
       
        <div class="dropdown-trigger">
          <button
            @click="dropDownButton"
            class="button"
             v-bind:class="{ noDetails: !openDetails, details: openDetails }"
            aria-haspopup="true"
            aria-controls="dropdown-menu6"
          >
            <svg-icon
                  type="mdi"
                  :path="menu"
                  aria-hidden="true"
                />
          </button>
        </div>

        <div
          v-show="dropDown"
          class="dropdown-menu"
          id="dropdown-menu6"
          role="menu"
        >
 
          <div class="dropdown-content">
            <div class="dropdown-item">
              <div class="share-list">
                <svg-icon
                  class="mr-2"
                  type="mdi"
                  :path="share"
                  aria-hidden="true"
                />
                <input
                  class="input-share"
                  type="button"
                  @click="dropDownButton()"
                  value="Partager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>  -->

  </div>
</template>

<script>
//import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiClose,
  mdiEmailOutline,
  mdiCalendarWeek,
  mdiDotsHorizontal, 
  mdiShareVariant

} from "@mdi/js";

export default {
  components: {
  //  SvgIcon,
  },
  name: "candidates",
  created() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  data() {
    return {
      allOpening: false,
      close: mdiClose,
      dropClose: false,
      isOpen: false,
      openCard: true,
      informationsOpen: true,
      follow: false,
      files: false,
      share: mdiShareVariant,
      dropDown: false,
      mail: mdiEmailOutline,
      calendar: mdiCalendarWeek,
            menu: mdiDotsHorizontal,
    };
  },
  props: {
    title: String,
    job: Number,
    name: String,
    intervention: Number,
    last_name: String,
    time_month: String,
    time_year: String,
  },
  methods: {
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
    format_date_year(value) {
      //function to pass date and display year
      if (value) {
        return this.$moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      //function to pass date and display month
      if (value) {
        return this.$moment(String(value)).format("MMMM");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.dropdown{

}
.div-job {
  grid-column: 1 / span 2;
  margin-left: 0.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.div-name-intervention {
  grid-column: 4/ span 5;
  padding: 0.25rem 0rem;
  display: flex;
 
  font-size: 11px;
  div{ 
    display: flex;
    flex-direction: column;
  align-items: center;
    justify-content: center;
  }

}

.svg-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3rem;
    max-height: 3rem;
    padding: 0.5rem;
    background-color: #E7E8E8;
    border-radius: 50%;
    margin: 0.25rem 0.5rem;
}

.svg{
    max-width: 1rem;
    max-height: 1rem;
}

.points-svg{
    margin: 1rem 1rem;
}
.div-date {
  grid-column: 9 / span 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    border-radius: 2px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.second-div-applies {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 1rem 0.2rem;
  font-family: $font-primary;
  color: black;
  font-size: 12px;
  height: fit-content;
  grid-column: span 3;
  margin-left: 1rem;
  @include grid;
  display: grid;
  .fav_item {
    display: flex;
    align-items: center;
    grid-column: 1 / span 12;
    position: relative;
    top: 10px;
  }

  .intervention-title {
    font-size: 10px;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

.salariat {
  background-color: $color-primary;
}

.freelance {
  background-color: #fa790f;
}

.alternance {
  background-color: #071e0b;
}

.details {
  background-color: #a8d0b0;
}

.points {
  cursor: pointer;
}


.share-list,
.details-list {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.share-list:hover,
.details-list:hover {
  color: $color-primary;
  border-left: 3px solid $color-primary;
}
.input-share,
.input-details {
  border: none;
  background: none;
  font-family: $font-primary;
  cursor: pointer;
}
.input-share:hover,
.input-details:hover {
  color: $color-primary;
  cursor: pointer;
}
  .position-title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 1rem;
    color: black;
    width: 25%;
  }
  .intervention-title {
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 4px;
  }
  div {
    .name-intervention-candidate {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 0rem 0.2rem;
      span {
        text-transform: uppercase;
      }
    }
  }

.button{
  border: none;
  padding: 0;
}

</style>
