<!--Component of details object -->
<template>
  <div class="main-div-details-card-candidate">
    <div class="second-div-card">
      <div class="div-content-names-posts">
        <span class="item" v-if="value.apply.data[0].job_id == 1">CDP</span>
        <span class="item" v-if="value.apply.data[0].job_id == 2">UX/UI</span>
        <span class="item" v-if="value.apply.data[0].job_id == 3">PO</span>
        <span>
          {{ value.first_name }}
          <span class="last-name">{{ value.last_name }}</span></span
        >
      </div>

      <div class="div-created-at">
        <span>{{ format_date_month(value.apply.data[0].created_at) }}</span>
        <span
          ><strong>{{ format_date_year(value.apply.data[0].created_at) }}</strong></span
        >
      </div>

      <div class="dropdown is-right is-active">
        <!-- little three points wrapper -->
        <div class="dropdown-trigger">
          <button
            @click="dropDownButton"
            class="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu6"
          >
            <points class="points" />
          </button>
        </div>

        <div
          v-show="dropDown"
          class="dropdown-menu"
          id="dropdown-menu6"
          role="menu"
        >
          <!-- content of dropdown little three points -->
          <div class="dropdown-content">
            <div class="dropdown-item">
              <div class="share-list">
                <svg-icon
                  class="mr-2"
                  type="mdi"
                  :path="share"
                  aria-hidden="true"
                />
                <input
                  class="input-share"
                  type="button"
                  @click="dropDownButton()"
                  value="Partager"
                />
              </div>
              <div class="details-list">
                <svg-icon
                  type="mdi"
                  :path="details"
                  aria-hidden="true"
                /><router-link
                  :to="{
                    name: 'Profil',
                    params: { id: value.id },
                  }"
                  ><input
                    @click="dropDownButton()"
                    class="input-details"
                    type="button"
                    value="Détails"
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <svg-icon
        class="close-cross"
        type="mdi"
        :path="close"
        aria-hidden="true"
        @click="closeCards"
      />
    </div>

    <div class="menu-choice-candidate">
      <div class="enumeration-menu">
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: informationsOpen }"
          @click="openInformations"
          >Informations</span
        >
      <!--  <span
          class="item-menu"
          v-bind:class="{ itemSelected: follow }"
          @click="openFollow"
          >Suivi</span
        > -->
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: files }"
          @click="openFiles"
          >Fichiers</span
        >
      </div>
      <div>
        <informations-details-side v-if="informationsOpen" :value="value" />
        <follow-side-details v-if="follow" :value="value" />
        <files-side-details v-if="files" :value="value" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiClose,
  mdiDotsHorizontal,
  mdiShareVariant,
  mdiAccountDetails,
} from "@mdi/js";
import informationsDetailsSide from "../elementComponents/informationsSideDetails.vue";
import filesSideDetails from "../elementComponents/filesSideDetails.vue";
import followSideDetails from "../elementComponents/followSideDetails.vue";
import points from "../../components/buttons/points.vue";

export default {
  components: {
    SvgIcon,
    informationsDetailsSide,
    filesSideDetails,
    followSideDetails,
    points,
  },
  data() {
    return {
      close: mdiClose,
      menu: mdiDotsHorizontal,
      dropClose: false,
      isOpen: false,
      openCard: true,
      informationsOpen: true,
      follow: false,
      files: false,
      dropDown: false,
      share: mdiShareVariant,
      details: mdiAccountDetails,
    };
  },
  props: ["value", "parentState"],
  methods: {
    openFollow() {
      this.informationsOpen = false;
      this.follow = true;
      this.files = false;
    },
    openFiles() {
      this.informationsOpen = false;
      this.follow = false;
      this.files = true;
    },
    openInformations() {
      this.informationsOpen = true;
      this.follow = false;
      this.files = false;
    },
    closeCards() {
      this.$emit("close");
    },
    format_date_year(value) {
      if (value) {
        return this.$moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      if (value) {
        return this.$moment(String(value)).format("MMMM");
      }
    },
    toggleOpeningState(state) {
      this.isOpen = state;
    },
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
  },
  watch: {
    parentState(newValue) {
      this.isOpen = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.second-div-card {
  display: flex;
  justify-content: space-around;
}
.div-content-names-posts {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: $font-primary;
  color: black;
  font-size: 12px;
  padding: 1rem;
  height: fit-content;
}

.div-created-at {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: $font-primary;
  color: black;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: fit-content;
}
.last-name {
  text-transform: uppercase;
}

.item {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
}

.item-menu {
  margin: 2rem 1rem 0rem 0.2rem;
  color: #a1a4a7;
  cursor: pointer;
}

.enumeration-menu {
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e8e8;
}
.menu-choice-candidate {
  font-size: 16px;
}

.itemSelected {
  color: $color-primary;
  margin-bottom: -1px;
  border-bottom: 1px solid $color-primary;
}

.share-list,
.details-list {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.share-list:hover,
.details-list:hover {
  color: $color-primary;
  border-left: 3px solid $color-primary;
}
.input-share,
.input-details {
  border: none;
  background: none;
  font-family: $font-primary;
  cursor: pointer;
}
.input-share:hover,
.input-details:hover {
  color: $color-primary;
  cursor: pointer;
}

.points {
  cursor: pointer;
}

.button {
  border: none;
  outline: none;
}
.button:focus {
  outline: none !important;
}

.close-cross{
  cursor: pointer;
}
</style>
