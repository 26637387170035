<template>
  <div
    class="second-div-applies"
    @dblclick="openCardSide"
    v-bind:class="{ noDetails: !openDetails, details: openDetails }"
  >
   <div class="evaluation-div"> 
    <span>Informations de contact</span>
     </div>
     <div class="main-div-informations">
        <div class="contact-div-info">
    <span class="svg-icon"> <svg-icon class="svg" type="mdi" :path="mail" aria-hidden="true" /> </span>

        <informations-contact-component
            :elementTitle="'Email'"
            :elementContent="email"
          />
        </div>    
        <div class="contact-div-info">
        <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="phoneMdi" aria-hidden="true" /></span>
          <informations-contact-component
            :elementTitle="'Téléphone'"
            :elementContent="phone"
          />
        </div>
        <div class="contact-div-info">
    <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="mdiLinkedin" aria-hidden="true" /></span>
          <informations-contact-component
            :elementTitle="'Linkedin'"
            :elementContent="linkedin"
          />
        </div>
     </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import informationsContactComponent from "../elementComponents/informationsComponentColumn.vue";
import {
  mdiEmailOutline,
  mdiCalendarWeek, 
  mdiPencil,
  mdiPhone,
  mdiLinkedin 
} from "@mdi/js";

export default {
  components: {
    SvgIcon,
    informationsContactComponent

  },
  name: "candidates",
  created() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  data() {
    return {
      dropDown: false,
      mail: mdiEmailOutline,
      calendar: mdiCalendarWeek,
      pencil: mdiPencil,
      phoneMdi: mdiPhone,
      mdiLinkedin: mdiLinkedin,
    };
  },
  props: {
    email: String,
    linkedin: String,
    phone: String
  },
  methods: {
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.evaluation-div{
  grid-column: 1/ span 12;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.5rem;
  border-bottom: 0.1rem solid #E7E8E8
}

.main-div-informations{
    grid-column: 1/span 12;
}

.contact-div-info{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.svg-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3rem;
    max-height: 3rem;
    padding: 0.5rem;
    background-color: #E7E8E8;
    border-radius: 50%;
    margin: 0.5rem 0.5rem;
}

.svg{
    max-width: 1rem;
    max-height: 1rem;
}

.second-div-applies {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 2rem 0.2rem;
  font-family: $font-primary;
  color: black;
  font-size: 12px;
  height: fit-content;
  grid-column: span 3;
  margin-left: 1rem;
  @include grid;
  display: grid;
  .fav_item {
    display: flex;
    align-items: center;
    grid-column: 1 / span 12;
    position: relative;
    top: 10px;
  }
}

</style>
