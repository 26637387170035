<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-menu">
      <breadcrumb />
    </div>
    <div class="navbar-menu is-relative">
      <app-bar-search></app-bar-search>
    </div>
    <div class="navbar-menu">
      <a @click="openModal()" class="navbar-item">
        <plus-icon/>
      </a>
      <a class="navbar-item" href="https://windu.green-conseil.com" target="_blank">
        <cubes-icon/>
      </a>
      <a class="navbar-item">
        <avatar/>
     <!--{{ identity.first_name }} {{ identity.last_name }}--> 
        <chevron-icon />
      </a>
    </div>
  </nav>
  <modal-candidate v-if="modalOpen" :open="modalOpen" />
</template>

<script>
import AppBarSearch from "@/components/navigation/AppBarSearch";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import PlusIcon from "@/components/icons/plusIcon";
import CubesIcon from "@/components/icons/cubesIcon";
import ChevronIcon from "@/components/icons/chevronIcon"
import Avatar from "@/components/Avatar";
import modalCandidate from "../../views/modalCandidate.vue";
import { mapGetters} from 'vuex';

export default {
  components: {
    Avatar,
    PlusIcon,
    CubesIcon,
    ChevronIcon,
    AppBarSearch,
    Breadcrumb,
    modalCandidate
  },
  data() {
    return {
      isOpen: false,
      modalOpen: false
    }
  },
  created() {
    this.$store.dispatch("identity/fetch");
  },
  mounted() {
    this.$store.dispatch("identity/fetch");
  }, 
  computed: {
    ...mapGetters('identity', {identity: 'identity'}),},
  methods: {
    openModal() {
      this.modalOpen = !this.modalOpen
    }, 
    getUser(){
      this.$store.dispatch("identity");
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .navbar-menu {
    padding: 0 2rem;
    &:first-of-type {
      max-width: 16rem;
    }
    &:nth-child(2n) {
      padding: 0;
      border-right: 1px solid rgba(0, 0, 0, 12%);
      border-left: 1px solid rgba(0, 0, 0, 12%);
    }
    &:last-child {
      a {
        flex: 1;
        justify-content: center;
        &:last-child {
          display: flex;
          justify-content: space-evenly;
          flex: 2;

          span {
            font-weight: 500;
          }

          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}
</style>