<!--base page with navigation bar -->
<template>
  <div>
    <div class="content" @keyup.enter="test">
      <app-bar />
      <div style="padding: 1.5rem 2rem 1.5rem 1.5rem;">
        <router-view />
      </div>
    </div>
    
    <side-bar class="sidebar" />
  </div>
</template>

<script>
import AppBar from "./components/navigation/AppBar.vue";
import SideBar from "./components/navigation/SideBarMenu.vue";

export default {
  components: {
    AppBar,
    SideBar,
  },
  methods: {
    test() {

    }
  }
};
</script>

<style lang="scss">
@import url("./assets/fonts/fonts.scss");

body {
  margin: 0;
  padding: 0;
}
.content {
  margin-left: 4rem;
}
</style>
