<template>
  <div class="navbar-item" v-if="this.$route.name">
    <router-link :to="currentPage.link">
      {{ currentPage.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    currentPage () {
      console.log(this.$route)
      return { name: this.$route.meta.verboseName, link: this.$route.name }
    }
  }
}
</script>

<style scoped lang="scss">
div.navbar-item {
  font-family: Poppins,sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);

}
</style>