<!--Component of details object -->
<template>
  <div class="main-div-contacts-side-component">
    <drop-down title="Informations de contact" class="testing">
      <template v-slot:content>
        <div class="main-div-informations-contact">
          <informations-contact-component
            :elementTitle="'Email'"
            :elementContent="value.email"
          />
          <informations-contact-component
            :elementTitle="'Téléphone'"
            :elementContent="value.phone"
          />
          <informations-contact-component
            :elementTitle="'Linkedin'"
            :elementContent="value.linkedin"
          />
        </div>
      </template>
    </drop-down>

    <drop-down title="Informations de candidature">
      <template v-slot:content>
        <div class="main-div-informations-contact">
          <informations-contact-component
            class="no-link"
            :elementTitle="'Age'"
            :elementContent="calculAge()"
          >
            <template v-slot:content1> Ans</template>
          </informations-contact-component>

          <informations-contact-component
            :elementTitle="'Expérience'"
            :elementContent="value.apply.data[0].experience"
          >
            <template v-slot:content1> Ans</template>
          </informations-contact-component>
          <informations-contact-component :elementTitle="'Statut'">
            <template v-if="value.apply.data[0].intervention_id == 1" v-slot:content1
              >Freelance</template
            >
            <template v-slot:content2 v-if="value.apply.data[0].intervention_id == 3">
              Alternance
            </template>
            <template v-slot:content3 v-if="value.apply.data[0].intervention_id == 2">
              CDI</template
            >
            <template v-slot:content4 v-if="value.apply.data[0].intervention_id == 4">
              Stage</template
            >
          </informations-contact-component>
          <informations-contact-component
            :elementTitle="'Rémunération'"
            :elementContent="value.apply.data[0].remuneration"
          >
            <template v-if="value.apply.data[0].intervention_id == 1" v-slot:content1>
              €/jour
            </template>
            <template v-slot:content2 v-if="value.apply.data[0].intervention_id == 3">
              €/an
            </template>
            <template v-slot:content4 v-if="value.apply.data[0].intervention_id == 4">
              €/an
            </template>
            <template v-slot:content3 v-if="value.apply.data[0].intervention_id == 2">
              €/an
            </template>
          </informations-contact-component>
        </div>
      </template>
    </drop-down>

    <drop-down title="Localisation">
      <template v-slot:content>
        <div class="main-div-informations-contact">
          <informations-contact-component
            :elementTitle="'Adresse'"
            :elementContent="value.name_street"
          />
          <informations-contact-component
            :elementTitle="'Ville'"
            :elementContent="value.city"
          />
          <informations-contact-component
            :elementTitle="'Code postal'"
            :elementContent="value.postal_code"
          />
        </div>
      </template>
    </drop-down>
    <drop-down title="Documents partagés">
      <template v-slot:content>
        <div class="main-div-informations-contact">

        <!--  <a v-if="value.apply.data[0].document.length > 0" :href="`http://localhost:8010/api/documents/${value.apply.data[0].document[0].hash}/download`" ><span class="file-type">CV</span> </a> <span v-if="value.apply.data[0].document.length === 0">CV</span> -->
          <card-document v-if="value.apply.data[0].document.length > 0" :documentSide="boolean">
            <template v-slot:acronyme-doc> CV</template>
            <template v-slot:name-doc> <a v-if="value.apply.data[0].document.length > 0" :href="`https://stardust.green-conseil.com/api/documents/${value.apply.data[0].document[0].hash}/download`" ><span class="file-type">{{value.apply.data[0].document[0].filename}}</span> </a> </template>
          </card-document>

          <card-document v-if="value.apply.data[0].document.length === 0" :documentSide="boolean">
            <template v-slot:acronyme-doc> CV </template>
            <template v-slot:name-doc>
              {{ value.linkedin }}_lettre.pdf
            </template>
          </card-document>
        </div>
      </template>
    </drop-down>

    <drop-down title="Motivations">
      <template v-slot:content>
        <div class="motivation">
          <p>{{ value.apply.data[0].why_me }}</p>
        </div>
      </template>
    </drop-down>
  </div>
</template>

<script>
import moment from "moment";
import dropDown from "../cards/dropDown.vue";
import cardDocument from "../cards/cardDocument.vue";
import informationsContactComponent from "../elementComponents/informationsContactComponent.vue";
import { mdiClose, mdiDotsHorizontal } from "@mdi/js";

const getAge = (birthDate) =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export default {
  components: {
    dropDown,
    informationsContactComponent,
    cardDocument,
  },
  data() {
    return {
      close: mdiClose,
      menu: mdiDotsHorizontal,
      dropClose: false,
      isOpen: false,
      openCard: true,
      boolean: true,
    };
  },
  props: ["value"],
  methods: {
    closeCards() {
      this.$emit("close");
    },
    closeCard() {
      this.isOpen = !this.isOpen;
    },
    format_date_year(value) {
      if (value) {
        return moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      if (value) {
        return moment(String(value)).format("MMMM");
      }
    },
    toggleOpeningState(state) {
      this.isOpen = state;
    },
      calculAge() {
      // function calcul age of candidate with birthdate
      return getAge(this.value.birth_at);
    },
  },
  watch: {
    parentState(newValue) {
      this.isOpen = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-details-card-candidate {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.div-content-names-posts {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: $font-primary;
  color: black;
  font-size: 16px;
  padding: 1rem;
  height: fit-content;
}

.div-created-at {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: $font-primary;
  color: black;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: fit-content;
}
.last-name {
  text-transform: uppercase;
}

.item {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
}

.main-div-contacts-side-component {
  overflow-y: scroll;
  max-height: 60vh;
  @include scroll-bar;
}

.motivation {
  padding: 2rem;
  margin-right: auto;
  margin-left: auto;
  text-align: justify;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
}
.motivation p {
  padding-right: 0.5rem;
  overflow-y: scroll;
  max-height: 18.25rem;
}

.main-div-contacts-side-component::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-contacts-side-component::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-contacts-side-component::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #585a5c;
}
</style>
