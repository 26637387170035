<!--Component of details object -->
<template>
  <div>
    <div class="informations-div-column">
        <span class="element-title"> {{elementTitle}}</span>
        <span v-if="elementTitle == 'Email' || elementTitle == 'Linkedin' || elementTitle == 'Téléphone'" > <a :href="elementContent"> {{elementContent}} </a> </span>
        <span v-else > {{elementContent}}
        <slot name="content1"></slot> 
        <slot name="content2"></slot>
        <slot name="content3"></slot>
        <slot name="content4"></slot>
        </span>
        
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiDotsHorizontal } from "@mdi/js";

export default {
  components: {
  },
  data() {
    return {
      close: mdiClose,
      menu: mdiDotsHorizontal,
      dropClose: false,
      isOpen: false,
      openCard: true,
    };
  },
  props: {
    elementTitle: String,
    elementContent: [String, Number]
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.informations-div-column{
    font-size: 12px;
    display: flex;
    flex-direction: column;
}
.element-title{
    color: #898D90;
    margin-right: 1rem;
}

a{
    text-decoration: underline;
}
</style>
