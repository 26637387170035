// creating request axios to access back-end of the bunk
import axios from "axios";

const axiosService = axios.create({
  baseURL: process.env.VUE_APP_API_CANDIDATES,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

// axiosService.interceptors.request.use(
//     async config => {
//       if (config.url !== process.env.VUE_APP_HOST_PORTAL + "check") {
//         await axiosService
//             .get(process.env.VUE_APP_HOST_PORTAL + "check")
//             .catch(() => {
//               window.location.href = process.env.VUE_APP_HOST_WINDU + "verify";
//             });
//       }
//
//       return config;
//     },
//     error => {
//       return Promise.reject(error);
//     }
// );
/*
axiosService.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
          window.location.href = process.env.VUE_APP_HOST_WINDU + "verify";
        return;
      }
      return Promise.reject(error);
    }
);*/

export default () => {
  return axiosService;
};
