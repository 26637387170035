<template>
  <article class="media" @mouseenter="isHovering = true" @mouseleave="isHovering = false">
    <figure class="media-left">
      <p class="image is-40x40">
        <img src="https://bulma.io/images/placeholders/128x128.png">
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{ first_name }} {{ last_name }}</strong>
         <slot></slot>
        </p>
      </div>
    </div>

    <div class="media-right" v-if="action && isHovering">
      <link-icon />
    </div>
  </article>
  <hr class="dropdown-divider">
</template>

<script>
import LinkIcon from '@/components/icons/LinkIcon'

export default {
  props: {
    first_name: {
      type: String,
      default: ''
    },
    id_profile: {
      type: Number, 
      default: null
    },
    last_name: {
      type: String,
      default: ''
    },
    created_at: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isHovering: false
    }
  },

  components: {
    LinkIcon
  }
}
</script>

<style scoped lang="scss">
.media {
  padding: .25rem 1rem;
  cursor: pointer;
  .media-content .content {
    margin-left: .5rem;
  }
  figure, figure.media-left {
    margin: 0;
    .image.is-40x40 {
      height: 40px;
      width: 40px;
    }
  }
  .media-right {
    align-self: center;
    svg {
      fill: rgba(0, 0, 0, 0.54);
      &:hover {
        fill: rgba(0,0,0, 84%)
      }
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
</style>