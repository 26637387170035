<template>
  <div class="avatar">
    <img :src="avatar" alt="Avatar mascotte">
  </div>
</template>

<script>
import avatar from "@/assets/super-greenie.png"

export default {
  data () {
    return {
      avatar: avatar
    }
  }
}
</script>

<style scoped lang="scss">
div.avatar {
  background-color: #fff;
  border-radius: 9999px;
  height: 40px;
  width: 40px;
  box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>