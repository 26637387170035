import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.scss';
import router from './router';
import store from './store'
import moment from 'moment'; 
moment.locale('fr');



//let app = createApp(App).use(store).use(router).mount('#app')

let app=createApp(App);

app.directive('click-outside', {
    bind: function (el, binding, vnode) {
        console.log(el)
        this.event = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', this.event)
    },
    unbind: function (el) {
        console.log(el)
        document.body.removeEventListener('click', this.event)
    },
});
app.config.globalProperties.$moment=moment;

app.use(store).use(router)
  .mount('#app');