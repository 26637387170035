<!--main page of candidatheque-->
<template>
  <div class="main-content-candidathque">
    <div class="filter-with-applies-div">
      <div class="title-bunk-table mt-4">
        <h1 class="main-title">Liste des candidats</h1>
        <span>Résultats pour la recherche</span>
      </div>
      <div class="filter">
        <div class="filter-main-div">
          <svg-icon type="mdi" :path="filter" aria-hidden="true" />
          <span>Filtre </span>
        </div>

        <div class="filter-component">
          <div class="secondary-filter-component">
            <span class="title-filter">Statut</span>
            <button @click="filterActive = !filterActive">
              <svg-icon
                v-if="filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="close"
                aria-hidden="true"
              />
              <svg-icon
                v-if="!filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="arrow"
                aria-hidden="true"
              />
            </button>
          </div>
          <div v-if="filterActive">
            <div v-for="status in statut" :key="status.id">
              <Filter class="filter-try"  :type="'list'" :value="status.id" :toto="'status'" :qualified="status.label">
                <template v-slot:content-filter>
                  <div>{{ status.label }}</div>
                </template>
              </Filter>
            </div>
          </div>
        </div>
        <hr />

        <div class="filter-component">
          <div class="secondary-filter-component">
            <span class="title-filter">Postes</span>
            <button @click="filterActive = !filterActive">
              <svg-icon
                v-if="filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="close"
                aria-hidden="true"
              />
              <svg-icon
                v-if="!filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="arrow"
                aria-hidden="true"
              />
            </button>
          </div>
          <div v-if="filterActive">
            <div v-for="job in this.$store.state.jobs.data" :key="job.id">
              <Filter class="filter-try" :type="'list'" :value="job.id" :toto="'job_id'">
                <template v-slot:content-filter>
                  <div>{{ job.label }}</div>
                </template>
              </Filter>
            </div>
          </div>
        </div>

        <hr />

        <div class="filter-component">
          <div class="secondary-filter-component">
            <span class="title-filter">Mode d'intervention</span>
            <button @click="filterActive = !filterActive">
              <svg-icon
                v-if="filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="close"
                aria-hidden="true"
              />
              <svg-icon
                v-if="!filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="arrow"
                aria-hidden="true"
              />
            </button>
          </div>
          <div v-if="filterActive">
            <div
              v-for="intervention in this.$store.state.interventions.data"
              :key="intervention.id"
            >
              <Filter
                class="filter-try"
                :value="intervention.id"
                :toto="'intervention_id'"
                :type="'list'"
              >
                <template v-slot:content-filter>
                  <div>{{ intervention.label }}</div>
                </template>
              </Filter>
            </div>
          </div>
        </div>

        <div class="filter-component">
          <div class="secondary-filter-component">
            <span class="title-filter">Expérience</span>
            <button @click="filterActive = !filterActive">
              <svg-icon
                v-if="filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="close"
                aria-hidden="true"
              />
              <svg-icon
                v-if="!filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="arrow"
                aria-hidden="true"
              />
            </button>
          </div>
          <div v-if="filterActive">
            <Filter
              class="filter-try"
              :toto="'experience'"
              :type="'range'"
            >
            </Filter>

          </div>
        </div>

        <div class="filter-component">
          <div class="secondary-filter-component">
            <span class="title-filter">Rémunération</span>
            <button @click="filterActive = !filterActive">
              <svg-icon
                v-if="filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="close"
                aria-hidden="true"
              />
              <svg-icon
                v-if="!filterActive"
                class="icon-close-filter"
                type="mdi"
                :path="arrow"
                aria-hidden="true"
              />
            </button>
          </div>
          <div v-if="filterActive">
            <Filter
              class="filter-try"
              :toto="'remuneration'"
              :type="'range'"
            >
            </Filter>

          </div>
        </div>
   <!--  <Filter />-->
        <hr />

      </div>
      <card-candidatheque />
    </div>
  </div>
</template>

<script>
import CardCandidatheque from "./CardCandidatheque.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import Filter from "../components/cards/Filter.vue";
import { mdiFilterOutline, mdiClose, mdiChevronDown } from "@mdi/js";

export default {
  components: {
    CardCandidatheque,
    SvgIcon,
    Filter,
  },
  data() {
    return {
      isActive: true,
      applies: [],
      test: "test",
      candidates: [],
      filter: mdiFilterOutline,
      filterActive: true,
      detailsActive: false,
      close: mdiClose,
      swipe: true,
      arrow: mdiChevronDown,
      allOpening: false,
      showMoreFilter: false,
      jobs: [],
      values: [],
      valuesRemuneration: [],
      interventions: [],
      statut: [
        {
          id: 2,
          label: "Qualifié",
        },
        {
          id: 3,
          label: "Non retenu",
        },
        {
          id: 4,
          label: "Disqualifié",
        }
      ],
    };
  },
  created() {
    this.$store.dispatch("fetchJobs");
    this.jobs = this.$store.state.jobs.data;
    this.$store.dispatch("fetchInterventions");
    this.interventions = this.$store.state.interventions.data;
    this.$store.dispatch("fetchCandidates2");
    this.candidates = this.$store.state.list.data;
  },
  methods: {
    onClickChild(value) {
      console.log(value); // someValue
    },
    showMore() {
      this.showMoreFilter = !this.showMoreFilter;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
    validate() {
      this.values.push(this.value1, this.value2);
      console.log(this.values);
    },
    validateRemuneration() {
      this.valuesRemuneration.push(this.value3, this.value4);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.value-input {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  input {
    width: 40%;
    border-radius: 4px;
    border: 1px solid $color-primary;
  }
}

.validate-input {
  width: 30%;
  font-size: 12px;
  margin-left: auto;
  margin-right: 0.5rem;
  font-family: $font-primary;
  background-color: $color-primary;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.title-bunk-table {
  grid-column: 3 / span 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 2rem;
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  span {
    color: black;
    text-decoration: underline;
  }
}

.input-xp {
  display: flex;
  flex-direction: column;
}
.filter-with-applies-div {
  @include grid;
  display: grid;
  margin-left: 0.75rem;
  max-height: 80vh;
}

.filter {
  grid-column: 1 / span 2;
  overflow-y: scroll;
  max-height: 75vh;
  @include scroll-bar;
  position: relative;
  bottom: 70px;
  hr {
    width: 20%;
    background-color: $color-primary;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
  }
}

.filter::-webkit-scrollbar {
  display: none;
}

.filter-main-div {
  font-family: $font-primary;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

.filter-try {
  width: 100%;
  margin: 1rem 0rem;
}
.filter-component {
  box-shadow: 0px 3px 6px rgba(37, 150, 58, 0.1);
}

.icon-close-filter {
  max-width: 18px;
  max-height: 18px;
}

.secondary-filter-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
  button {
    background-color: white;
    border: none;
    cursor: pointer;
  }
}

.filter-button-div {
  display: flex;
  justify-content: center;
  button {
    color: $color-primary;
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    font-family: $font-primary;
    font-size: 12px;
    cursor: pointer;
    margin: 0.5rem;
    transition: 0.3s;
  }
  button:hover {
    color: white;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    transition: 0.3s;
  }
}

.buttons-filter {
  display: flex;
  justify-content: space-around;
}
.button-filter-init {
  width: 45%;
  border: 1px solid $color-primary;
  border-radius: 4px;
  font-family: $font-primary;
  font-size: 11px;
  padding: 0.3rem 0.5rem;
  text-align: center;
  cursor: pointer;
}

.other-button {
  background-color: white;
  color: $color-primary;
}

.init-button {
  background-color: $color-primary;
  color: white;
}
</style>
