<template>

    <div class="modal" :class="{ 'is-active': isActive}">
  <div class="modal-background" ></div>
  <div class="modal-content">

    <button class="modal-close is-large"  @click="modal(); !open" aria-label="close"></button>
<h1>Ajout d'un candidat</h1>

     <div class="step-one " v-show="currentPage === 1">
      <div>
      <label>Email</label>
    <input class="input" type="email" placeholder="Mail" v-model="candidate.email">
      </div>

      <div class="buttons-modal">
        <button v-show="currentPage !== 1" class="button-next" @click="prevPage">PRECEDENT</button>
        <button v-show="currentPage !== 3" class="button-next" @click="nextPage">SUIVANT</button>
      </div>
      
    </div>

    <div class="step-two" v-show="currentPage === 2">
      <div>
      <label>Email</label>
    <input class="input" type="email" placeholder="Mail" v-model="candidate.email">
      </div>

      <div class="inputs-line">
        <div>
          <label>Prénom</label>
          <input class="input" type="text" placeholder="Prénom" v-model="candidate.first_name">
        </div>
        
        <div class="middle-input" >
          <label>Nom</label>
            <input class="input" type="text" placeholder="Nom" v-model="candidate.last_name">
        </div>
       

         <div>
          <label>Date de naissance</label>
          <input class="input" type="date" placeholder="Date de début" v-model="candidate.birth_at">
        </div>
      </div>
     
      <div class="inputs-line">
        <div>
          <label>Téléphone</label>
          <input class="input input-left" type="tel" placeholder="Téléphone" v-model="candidate.phone">
        </div>
        
        <div>
          <label>LinkedIn</label>
           <input class="input input-right" type="text" placeholder="linkedin" v-model="candidate.linkedin">
        </div>
       
      </div>
      
      
      <div class="buttons-modal">
        <button v-show="currentPage !== 1" class="button-next" @click="prevPage">PRECEDENT</button>
        <button v-show="currentPage !== 3" class="button-next" @click="nextPage">SUIVANT</button>
      </div>
    </div>

    <div class="step-two" v-show="currentPage === 3">

      <div class="main-div-adress">
      <div>
      <label>Numéro de voie</label>
    <input class="input" type="number" v-model="candidate.number_street">
      </div>

      <div>
      <label>Nom de la voie</label>
    <input class="input" type="text" placeholder="nom de la rue" v-model="candidate.name_street">
      </div>
      </div>
 

      <div class="main-div-adress">
        <div>
          <label>Ville</label>
          <input class="input" type="text" placeholder="Ville" v-model="candidate.city">
        </div>
        
        <div>
          <label>Code postal</label>
            <input class="input" type="text" placeholder="code postal" v-model="candidate.postal_code">
        </div>
      
      </div>
    
       
      <div class="buttons-modal">
        <button v-show="currentPage !== 1" class="button-next" @click="prevPage">PRECEDENT</button>
        <button v-show="currentPage !== 4" class="button-next" @click="nextPage">SUIVANT</button>
      </div>
    </div>

    <div class="step-three" v-show="currentPage === 4">

      <div class="select-input">
        
        <select v-model="candidate.apply.intervention_id">
        <option disabled value="">Mode d'intervention</option>
        <option value="1">Freelance</option>
        <option value="2">Salariat</option>
        <option value="3">Alternance</option>
        <option value="3">Stage</option>
      </select>

      <select v-model="candidate.apply.job_id">
        <option disabled value="">Emploi</option>
        <option value="1">Chef de projet</option>
        <option value="2">Designer UX/UI</option>
        <option value="3">Product owner</option>
      </select>
      </div>

      <div class="is-flex">   
      <input class="input" type="number" placeholder="Remunération" v-model="candidate.apply.remuneration">
       <input class="input" type="number" placeholder="expérience" v-model="candidate.apply.experience">
       </div>

      <div class="input-start-motivation">
        <label>Date de démarrage</label>
       <input class="input" type="date" placeholder="Date de début" v-model="candidate.apply.start_at">
       <input class="input motivation-input" type="text" placeholder="motivation" v-model="candidate.apply.why_me">
      </div>
      <files :value="candidate.document" @input="fileUpdate" />
      
        <a class="btn"  @click="submitFiles()" id='button'>AJOUTER</a>
        <div class="buttons-modal">
        <button v-show="currentPage !== 1" class="button-next" @click="prevPage">PRECEDENT</button>
        <button v-show="currentPage !== 4" class="button-next" @click="nextPage">SUIVANT</button>
      </div>
    </div>

  </div>
    </div>
</template>

<script>
import axios from 'axios';
import Files from '../components/File.vue';

export default {
  data () {
    return {
      currentPage: 1,
      allOpening: false,
      isActive: true,
       candidate: {
        last_name: null,
        first_name: null,
        email: null,
        linkedin: null,
        birth_at: null,
        city:null,
        postal_code: null,
        phone: null,
        number_street: null,
        name_street: null,
        status: 2,
        apply: {
                remuneration: null,
                experience: null,
                why_me: null,
                intervention_id:1,
                job_id: 1,
                start_at: null
            }
      },
       document: {},
       stepOne: true,
       stepTwo: false,
       stepThree: false
       
    }
  },
  components: {
    Files
   // modalAddCandidate
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleAllOpeningState () {
      this.allOpening = !this.allOpening
    },
    testPost(){
        axios.post(`${process.env.VUE_APP_API_CANDIDATES}/candidates`, this.candidate)
        .then( response => console.log(response))
        .catch(error => console.log(error))
  },
  nextPage() {
        this.currentPage++;
      },
      prevPage() {
        this.currentPage--;
      },
  modal(){
    this.isActive = false
  },
  test(){
    console.log(this.candidate.document)
  },
   nextStep(){
    this.stepOne = !this.stepOne;
    this.stepTwo = !this.stepTwo
  },
  nextStepTwo(){
    this.stepTwo = !this.stepTwo;
    this.stepThree = !this.stepThree
  },
  nextStepThree(){
    this.stepThree = !this.stepThree
  },
  fileUpdate(file) {
    this.candidate.document = file
  },
  submitFiles() {
    const formData = new FormData()
    formData.append('document', this.candidate.document)
    formData.append('first_name', JSON.stringify(this.candidate.first_name))
    formData.append('last_name', JSON.stringify(this.candidate.last_name))
    formData.append('email', JSON.stringify(this.candidate.email))
    formData.append('phone', JSON.stringify(this.candidate.phone))
    formData.append('linkedin', JSON.stringify(this.candidate.linkedin))
    formData.append('birth_at', JSON.stringify(this.candidate.birth_at))
    formData.append('city', JSON.stringify(this.candidate.city))
    formData.append('postal_code', JSON.stringify(this.candidate.postal_code))
    formData.append('number_street', JSON.stringify(this.candidate.number_street))
    formData.append('name_street', JSON.stringify(this.candidate.name_street))
    formData.append('status', JSON.stringify(this.candidate.status))
    formData.append('apply[remuneration]', JSON.stringify(this.candidate.apply.remuneration))
    formData.append('apply[experience]', this.candidate.apply.experience)
    formData.append('apply[why_me]', JSON.stringify(this.candidate.apply.why_me))
    formData.append('apply[intervention_id]', JSON.stringify(this.candidate.apply.intervention_id))
    formData.append('apply[job_id]', JSON.stringify(this.candidate.apply.job_id))
    formData.append('apply[start_at]', this.candidate.apply.start_at)

    axios.post( `${process.env.VUE_APP_API_CANDIDATES}/candidates/`,
      formData,
      {
        headers: {
            "Access-Control-Allow-Origin": '*' 
        },
      }
    ).then(function(){
     
    })
    .catch(function(){
      error => console.log(error)
    });
    this.isActive = false
  },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables";

.modal-content{
  max-height: 70vh;
  font-family: $font-primary;
  background-color: white;
  border-radius: 4px;
  padding: 3rem;
}

.modal-background{
  background-color: rgba($color: #22181C40, $alpha: 0.25);
}

label{
  position: relative;
    top: 13px;
    color: #A1A4A7;
    z-index: 100;
    background-color: white;
    padding: 0rem 0.5rem;
    margin-left: 1rem;
    font-size: 12px;
}

.button-next,
.btn{
  margin: 2rem 0rem;
  padding: 0.5rem 1.5rem;
  border: none;
  background-color: $color-primary;
  font-family: $font-primary;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.buttons-modal{
  display: flex;
  justify-content: space-between;
}
.step-one,
.step-two{
  display: flex;
  flex-direction: column;
}

.inputs-line{
  display: flex;
  align-items: flex-end;
  margin: 1rem 0rem;
  justify-content: space-between;
  .middle-input{
    margin: 0rem 0.5rem;
  }
  .input-left{
    margin-right: 0.5rem;
  }
  .input-right{
    margin-left: 0.5rem;
  }

}

select{
  font-family: $font-primary;
  padding: 0.5rem 1rem;
  margin: 1rem 0rem;
  width: 49%;
  border: 1px solid #E7E8E8;
  border-radius: 4px;
  outline: none;
}

.select-input{
  display: flex;
  justify-content: space-between;
}

.step-three{
  input{
    width: 49%;
    margin: 0.5rem 0rem;
  }
  div{
    display: flex;
  }
  .is-flex{
    justify-content: space-between;
  }
  .input-start-motivation{
    display: flex;
    flex-direction: column;
    .motivation-input{
      width: 100%;
    }
  }
}

.main-div-adress{
  display: flex;
  div{
    margin: 0rem 0.25rem;
    width: 100%;
  }
}

.modal-close{
  position: absolute;
  background-color: #ADADAD;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
</style>
