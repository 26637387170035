<template>
<div class="main-div">
  <div class="main-div-profile-side-left">
    <div>
       </div>
    
    <card-side-left
    class="card-side-left"
     :job="$store.state.candidate.data.apply.data[0].job_id"
      :name="$store.state.candidate.data.first_name"
      :last_name="$store.state.candidate.data.last_name"
      :intervention="$store.state.candidate.data.apply.data[0].intervention_id"
      :time_month="format_date_month($store.state.candidate.data.apply.data[0].created_at)"
      :time_year="format_date_year($store.state.candidate.data.apply.data[0].created_at)"
    >
      <template v-slot:informationsJob> </template>
    </card-side-left>
    <informations-card
    :email="$store.state.candidate.data.email"
    :phone="$store.state.candidate.data.phone"
    :linkedin="$store.state.candidate.data.linkedin" />
  
  </div>

  <div class="menu-choice-candidate">
      <div class="enumeration-menu">
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: informationsOpen }"
          @click="openInformations"
          >Informations</span
        >
       <span
          class="item-menu"
          v-bind:class="{ itemSelected: follow }"
          @click="openFollow"
          >Suivi</span
        > 
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: files }"
          @click="openFiles"
          >Fichiers</span
        >
      </div>
      <div> 
        <information-profile v-if="informationsOpen" :value="$store.state.candidate.data" />
        <follow-profile v-if="follow" />
        <files-profile v-if="files" :value="$store.state.candidate.data" />
      </div>
    </div>
    <div>
       
       
        <files-side-details v-if="files" :value="value" />
      </div>
</div>

</template>

<script>
import moment from "moment";
import cardSideLeft from "../components/elementComponents/informationsProfileSideLeft.vue";
import informationsCard from "../components/elementComponents/informationsCard.vue";
import informationProfile from "../components/elementComponents/informationsProfile.vue";
import followProfile from "../components/elementComponents/followProfile.vue";
import filesProfile from "../components/elementComponents/filesProfile.vue";

import {
  mdiClose,
  mdiDotsHorizontal,
  mdiShareVariant,
  mdiAccountDetails,
} from "@mdi/js";

export default {
  components: {
    cardSideLeft,
    informationsCard,
    informationProfile,
    followProfile,
    filesProfile

  },
  name: "candidates",
  created() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  mounted() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  }, 
  watch: {
    searchQuery: function(){
      this.$store.dispatch("fetchOneCandidate", this.$route.params.id);

    },
    $route () {
      this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
    }
  },
  data() {
    return {
      allOpening: false,
      close: mdiClose,
      menu: mdiDotsHorizontal,
      dropClose: false,
      isOpen: false,
      openCard: true,
      informationsOpen: true,
      follow: false,
      files: false,
      dropDown: false,
      share: mdiShareVariant,
      details: mdiAccountDetails,
    };
  },
  props: ["value"],
  methods: {
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
    format_date_year(value) {
      //function to pass date and display year
      if (value) {
        return moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      //function to pass date and display month
      if (value) {
        return moment(String(value)).format("MMMM");
      }
    },
     openFollow() {
      this.informationsOpen = false;
      this.follow = true;
      this.files = false;
    },
    openFiles() {
      this.informationsOpen = false;
      this.follow = false;
      this.files = true;
    },
    openInformations() {
      this.informationsOpen = true;
      this.follow = false;
      this.files = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.main-div{
    @include grid;
  display: grid;
}

.enumeration-menu {
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e8e8;
}

.main-div-profile-side-left{
  grid-column: span 3
}

.menu-choice-candidate{
  grid-column: 4 / span 9;
  font-family: $font-primary;
  font-size: 16px;
}

.details {
  background-color: #a8d0b0;
}

.item-menu {
  margin: 2rem 1rem 0rem 0.2rem;
  color: #a1a4a7;
  cursor: pointer;
}

.enumeration-menu {
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e8e8;
}

.itemSelected {
  color: $color-primary;
  margin-bottom: -1px;
  border-bottom: 1px solid $color-primary;
}

</style>
