import { createRouter, createWebHistory } from 'vue-router';
import Candidatheque from "@/views/Candidatheque";
import Profil from "@/views/Profile";
import store from "@/store/index";

const routes = [
    {
        path: "/",
        redirect: "/bannette",
    },
    {
        // route to bunk link
        path:'/bannette',
        name: 'Bannette',
        component: () =>
            import(/* webpackChunkName: "bannette" */ "@/views/Bannette.vue"),
        meta: {
            verboseName: 'Bannette'
        }
    },
    {
        path: '/candidatheque',
        name: 'Candidathèque',
        component: Candidatheque
    },
    {
        path: '/profil/:id',
        name: 'Profil',
        component: Profil
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})



router.beforeEach((to, from, next) => {
    if (!store.getters["identity/identity"]) {
        store.dispatch("identity/fetch");
    }

    next();
});

export default router