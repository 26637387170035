<template>
  <div
    class="second-div-applies"
    @click="openCardSide"
    v-bind:class="{ noDetails: !openDetails, details: openDetails }"
  >
    <div class="fav_item">
      <div class="job-name-div">
      <div class="position-title">
        <span v-if="value.apply.data[0].job_id == 1">CDP</span>
        <span v-if="value.apply.data[0].job_id == 2">UX/UI</span>
        <span v-if="value.apply.data[0].job_id == 3">PO</span>
        <span v-if="value.apply.data[0].job_id == 4">MARKET</span>
        <span v-if="value.apply.data[0].job_id == 5">CDPRO</span>
        <span v-if="value.apply.data[0].job_id == 6">DA</span>
      </div>
      <div class="name-intervention-candidate">
        <div>
          {{ value["first_name"] }} <span>{{ value["last_name"] }}</span>
        </div>
        <div
          class="intervention-title freelance"
          v-if="value.apply.data[0].intervention_id == 1"
        >
          FREELANCE
        </div>
        <div
          class="intervention-title salariat"
          v-if="value.apply.data[0].intervention_id == 2"
        >
          SALARIAT
        </div>
        <div
          class="intervention-title alternance"
          v-if="value.apply.data[0].intervention_id == 3"
        >
          ALTERNANCE
        </div>
        <div
          class="intervention-title stage"
          v-if="value.apply.data[0].intervention_id == 4"
        >
          STAGE
        </div>
      </div>
      </div>
      <div class="dropdown is-right is-active">
        <!-- little three points wrapper -->
        <div class="dropdown-trigger">
          <button
            @click="dropDownButton"
            class="button"
             v-bind:class="{ noDetails: !openDetails, details: openDetails }"
            aria-haspopup="true"
            aria-controls="dropdown-menu6"
          >
            <svg-icon
                  type="mdi"
                  :path="menu"
                  aria-hidden="true"
                />
          </button>
        </div>

        <div
          v-show="dropDown"
          class="dropdown-menu"
          id="dropdown-menu6"
          role="menu"
        >
          <!-- content of dropdown little three points -->
          <div class="dropdown-content">
            <div class="dropdown-item">
              <div class="share-list">
                <svg-icon
                  class="mr-2"
                  type="mdi"
                  :path="share"
                  aria-hidden="true"
                />
                <input
                  class="input-share"
                  type="button"
                  @click="dropDownButton()"
                  value="Partager"
                />
              </div>
              <div class="details-list">
                <svg-icon
                  type="mdi"
                  :path="details"
                  aria-hidden="true"
                /><router-link
                  :to="{
                    name: 'Profil',
                    params: { id: value.id },
                  }"
                  ><input
                    @click="dropDownButton()"
                    class="input-details"
                    type="button"
                    value="Détails"
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   <!--   <drop-down-candidatheque
      :detailsState="openDetails"
      :parentState="parentState"
    >
      <template class="interviewer-main-div" v-slot:content>
        
      </template>
    </drop-down-candidatheque>-->
    <div class="time-apply">
      <span>{{ format_date_month(value.created_at) }}</span>
      <span>{{ format_date_year(value.created_at) }}</span>
    </div>

    
  </div>
  <details-component-card
      class="div-fixed-card-candidate"
      v-if="openDetails"
      :value="value"
      :test="openDetails"
      @close="openDetails = false"
    />
</template>

<script>
//import dropDownCandidatheque from "../components/elementComponents/dropDownCandidatheque.vue";
import detailsComponentCard from "../components/cards/cardDetailsCandidateSide.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiDotsHorizontal,   mdiShareVariant,
  mdiAccountDetails,  } from "@mdi/js";


export default {
  name: "candidates",
  components: {
   // dropDownCandidatheque,
    detailsComponentCard,
    SvgIcon
  },
  props: ["value", "parentState"],
  data() {
    return {
      candidates: [],
      allOpening: false,
      openDetails: false,
      isOpen: false,
      menu: mdiDotsHorizontal,
      dropDown: false,
            share: mdiShareVariant,
      details: mdiAccountDetails,
    };
  },
   watch: {
        parentState (newValue) {
            this.isOpen = newValue
        }
    },
  methods: {
    onClickChild(value) {
      console.log(value); // someValue
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
     toggleOpeningState(state) {
            this.isOpen = state
     },
    openCardSide() {
      // function to open card side informations candidate
      this.openDetails = !this.openDetails;
    },
    format_date_year(value) {
      //function to pass date and display year
      if (value) {
        return this.$moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      //function to pass date and display month
      if (value) {
        return this.$moment(String(value)).format("MMMM");
      }
    },
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.job-name-div{
  display: flex;
  width: 90%;
  div{
    margin-left: 0.5rem;
  }
}

.button{
  padding: 0;
  max-height: 1rem;
  max-width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.second-div-applies {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 1rem 0.2rem;
  font-family: $font-primary;
  color: black;
  font-size: 10px;
  height: fit-content;
  @include grid;
  display: grid;
  .fav_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / span 12;
    position: relative;
    top: 10px;
  }

.share-list,
.details-list {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.share-list:hover,
.details-list:hover {
  color: $color-primary;
  border-left: 3px solid $color-primary;
}
.input-share,
.input-details {
  border: none;
  background: none;
  font-family: $font-primary;
  cursor: pointer;
}
.input-share:hover,
.input-details:hover {
  color: $color-primary;
  cursor: pointer;
}
  .position-title {
    font-size: 12px;
    font-weight: bold;
    margin-left: 1rem;
    color: black;
  }
  .intervention-title {
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 4px;
  }
  div {
    .name-intervention-candidate {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 0rem 0.2rem;
      span {
        text-transform: uppercase;
      }
    }
  }
}

.div-dropdown-interviewer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.evaluation-circle {
  max-width: 24px;
}

.interviewer-main-div {
  img {
    max-width: 24px;
  }
}

.user-logo {
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.5rem;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.evaluation-slot {
  font-size: 10px;
  font-weight: bold;
  margin: 1rem 2rem;
}
.post-entitled {
  width: 5.5rem;
  height: 3rem;
  background-color: $color-primary;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.salariat {
  background-color: $color-primary;
  color: white;
}

.freelance {
  background-color: #fa790f;
  color: white;
}

.alternance {
  background-color: #071e0b;
  color: white;
}

.stage{
  background-color: #D3EAD7;
  color: black;
  font-weight: bold;
}
.time-apply {
  //border: 1px solid black;
  background-color: white;
  color: #949494;
  border-radius: 2px;
  padding: 0.1rem 0.2rem;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.3);
  grid-column: 10 / span 3;
  position: relative;
  top: 10px;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-fixed-card-candidate {
  position: absolute;
  top: 0px;
  right: 0;
  width: 40%;
  height: 100vh;
  max-height: 80vh;
  z-index: 10;
  background-color: white;
}

.details {
  background-color: #a8d0b0;
}

.main-div-card-bunk {
  max-height: 70vh;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.main-div-doc {
  display: flex;
}


.header-card {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.main-div-entitled {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button-check {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.choice {
  display: flex;
  align-items: center;
}

.choices-main-div {
  display: flex;
  justify-content: space-between;
}

.points {
  cursor: pointer;
}

.button {
  border: none;
  outline: none;
}
.button:focus {
  outline: none !important;
}



</style>
