import identity from "@/services/identity";

const state = {
    identity: null
};

// getters
const getters = {
    identity: state => state.identity
};

// actions
const actions = {
    fetch({ commit }) {
        identity
            .fetch()
            .then(resp => {
                commit("setIdentity", resp);
            })
            .catch(error => {
                console.log(error);
            });
    }
};

// mutations
const mutations = {
    setIdentity(state, { data }) {
        state.identity = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
