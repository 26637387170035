<template>
  <div class="tabs is-relative">
    <ul>
      <li v-for="(el, index) in tabs" :key="index" @click="onTab(index)"
          :class="{'is-active': index === active}">
        <a>{{ el }}</a>
      </li>
    </ul>
<!--    <span class="is-adornment">toto</span>-->
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default() {
        return ['1', '2', '3']
      }
    }
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    onTab(tab) {
      this.active = tab
      this.$emit('onTab', tab)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs:not(:last-child) {
  margin-bottom: .5rem;
  .is-adornment {
    position: absolute;
    top: 0;
    right: .75rem;
    height: 100%;
  }
  ul {
    margin: 0;
  }
}
</style>

