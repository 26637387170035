<!-- component side bar navigation-->
<template>
  <nav class="navbar-main" role="navigation" aria-label="main navigation">
    <div v-bind:class="{ close: !isActive, open: isActive }" class="navbar">
      <!-- burger menu -->
      <button v-if="!isActive" className="burger-button" @click="wrapper">
        <div className="burger-button-line" />
        <div className="burger-button-line" />
        <div className="burger-button-line" />
      </button>

      <div v-if="isActive" class="div-close-side-menu">
        <button className="close-button mt-4 pl-2" @click="wrapper">
          <i><svg-icon class="icon-svg" type="mdi" :path="arrow"></svg-icon></i>
        </button>
      </div>

      <div class="main-div-icon-menu">
       <!-- <div class="icon-title-div">
          Dashboard link icon during open & close side bar 
          <router-link
            class="desactive-router"
            v-bind:class="{ divClose: !isActive, divOpen: isActive }"
            to="/essai"
            active-class="active-router"
            ><svg-icon class="icon-svg" type="mdi" :path="dashboard"></svg-icon>
            <icon-title v-if="isActive" link="Dashboard">
              <template v-slot:content-title> Dashboard </template>
            </icon-title></router-link
          >
        </div> -->

        <div class="icon-title-div" v-bind:class="{ mainDivOpen: isActive }">
          <!-- Bunk link icon during open & close side bar -->
          <router-link
            v-bind:class="{ divClose: !isActive, divOpen: isActive }"
            class="desactive-router"
            to="/bannette"
            active-class="active-router"
            ><svg-icon class="icon-svg" type="mdi" :path="mapActive"></svg-icon>
            <icon-title v-if="isActive" link="Bunk">
              <template v-slot:content-title> Bannette </template>
            </icon-title></router-link
          >
        </div>

        <div class="icon-title-div">
          <!-- Candidate library link icon during open & close side bar -->
          <router-link
            class="desactive-router"
            v-bind:class="{ divClose: !isActive, divOpen: isActive }"
            to="/candidatheque"
            active-class="active-router"
            ><svg-icon class="icon-svg" type="mdi" :path="account"></svg-icon>
            <icon-title v-if="isActive" link="candidathèque">
              <template v-slot:content-title> Candidathèque </template>
            </icon-title></router-link
          >
        </div>
      </div>
      <div class="div-logo-green py-3">
        <i class="icon is-medium green-logo"><green-light /></i>
      </div>
    </div>
  </nav>
</template>
<script>
import greenLight from "../icons/greenIonLight.vue";
import iconTitle from "../iconTitle.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiViewDashboardOutline,
  mdiBadgeAccountHorizontalOutline,
  mdiArrowLeftThin,
  mdiMapLegend,
} from "@mdi/js";

export default {
  components: {
    greenLight,
    iconTitle,
    SvgIcon,
  },
  data() {
    return {
      isActive: false,
      dashboard: mdiViewDashboardOutline,
      account: mdiBadgeAccountHorizontalOutline,
      arrow: mdiArrowLeftThin,
      mapActive: mdiMapLegend,
    };
  },
  methods: {
    wrapper() {
      // wrapper to open & close side bar navigation (burger menu)
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
@import "../../assets/variables.scss";

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 4rem;
  background-color: $color-primary;
  transition: 300ms;
  z-index: 200;
}

.main-div-icon-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.active-router {
  background: $color-active;
  width: 100%;
  display: flex;
  justify-content: center;
}

.desactive-router {
  display: flex;
  height: 4rem;
  align-items: center;
}

.icon-title-div:hover {
  background-color: $color-hover;
}

.divOpen {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.mainDivOpen {
  display: flex;
  justify-content: space-between;
}
a.desactive-router.divOpen {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.icon-title-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-open {
  width: 12rem;
}

.open {
  width: 12rem;
}
.icon-text {
  display: flex;
  align-items: center;
}
.icon-svg {
  color: white;
}

.burger-button {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 18px;
  width: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 20px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

.close-button {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-hover;
  border: none;
  cursor: pointer;
  box-sizing: content-box;
  transition: all 2s ease;
  &:focus {
    outline: none;
  }
}

.div-close-side-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.burger-button-line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  border-radius: 3px;
}

.menu-title {
  @include title-menu-slide-navbar-inactive;
}

.div-logo-green {
  position: absolute;
  bottom: 0;
  background-color: #a7d5b0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
