<!--Component of dropdown object -->
<template>
  <div class="dropdown is-active">
    <div class="dropdown-trigger">
      <button
        class="drop-down-button"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        @click="toogle"
      >
        <h1 class="title-drop-down">{{ title }}</h1>
        <span class="icon">
          <svg-icon
            v-if="!dropClose"
            type="mdi"
            :path="chevronOpen"
            aria-hidden="true"
          />
          <svg-icon
            v-if="dropClose"
            type="mdi"
            :path="chevronClose"
            aria-hidden="true"
          />
        </span>
      </button>
    </div>
    <div v-if="!dropClose" class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <slot name="content"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

export default {
  props: {
    title: String,
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      chevronClose: mdiChevronDown,
      chevronOpen: mdiChevronUp,
      dropClose: false,
    };
  },
  methods: {
    toogle() {
      // toogle open & close dropdown
      this.dropClose = !this.dropClose;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.dropdown {
  width: 100%;
  border: none;

  display: block;
}

.dropdown-trigger {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #E7E8E8;
}

.dropdown-trigger:focus {
  outline: none;
}

.drop-down-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  outline: none;
  text-align: inherit;
  padding: 5px;
  margin-top:20px;
  background-color: white;
  cursor: pointer;
  h1 {
    font-family: $font-primary;
    font-size: $font-medium;
  }
}

.title-drop-down{
  font-weight: bold;
}

.dropdown-content {
  border: none;
  box-shadow: inherit;
  margin-left: auto;
  margin-right: auto;
}
.dropdown-menu {
  position: inherit;
  display: flex;
  z-index: 0;
  min-width: 0;
}
</style>
