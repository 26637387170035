<!--Component of details object -->
<template>
  <div class="main-div-note">

    <div class="note-history">
      <div class="div-note-svg">
        CV
      </div>

      <div v-if="value.apply.data[0].document.length === 0" class="second-div-history">
        <div class="mail-candidate">
          <img class="user-logo" src="../../assets/icons/padme_user.svg" />
          <span>{{value.first_name}}</span>
          <a>curriculum_vitae_{{value.first_name}}</a>
        </div>
        <span class="date-note">Lundi 15 Novembre 2021</span>
      </div>

      <div  v-if="value.apply.data[0].document.length > 0"  class="second-div-history">
        <div class="mail-candidate">
          <img class="user-logo" src="../../assets/icons/padme_user.svg" />
          <span>{{value.first_name}}</span>
          <a :href="`${process.env.VUE_APP_HOST_STARDUST}/documents/${value.apply.data[0].document[0].hash}/download`" ><span class="file-type">{{value.apply.data[0].document[0].filename}}</span> </a>
        </div>
        <span class="date-note">Lundi 15 Novembre 2021</span>
      </div>
    </div>


  </div>
</template>

<script>
import { mdiEmailPlusOutline, mdiEmailOutline} from "@mdi/js";

export default {
  components: {
  },
  data() {
    return {
      dropClose: false,
      isOpen: false,
      openCard: true,
      mailCandidate: mdiEmailPlusOutline,
      mail: mdiEmailOutline
    };
  },
  props: ["value"],
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-note {
  overflow-y: scroll;
  max-height: 60vh;
}

.main-div-note::-webkit-scrollbar {
  display: none;
}

.note-history{
  display: flex;
  align-items: center;
  margin: 2rem;
  font-size: 10px;
}

.div-note-svg{
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 40px;
  max-height: 40px;
    font-family: $font-primary-semi-bold;
  font-size: 14px;
  color: $color-primary;
}

.user-logo {
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.mail-candidate{
  display: flex;
  align-items: center;
  margin-left: 1rem;
  span{
    margin-right: 0.5rem;
  }
}

.date-note{
  margin-left: 3rem;
  color: #A1A4A7;
}


</style>
