<!--Component of details object -->
<template>
  <div class="main-div-details-card-candidate">

    <div class="menu-choice-candidate">
      <div class="enumeration-menu">
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: informationsOpen }"
          @click="openInformations"
          >Détails du profil</span
        >
        <span
          class="item-menu"
          v-bind:class="{ itemSelected: cv }"
          @click="openCv"
          >CV</span
        >
      </div>
      
    </div>
    <div>
        <component-informations-contact-profile v-if="informationsOpen" :value="value"
        :linkedin="value.linkedin"
        :age="calculAge()"
        :birth_at="value.birth_at"
        :address="value.name_street"
        :postal_code="value.postal_code"
        :statut="value.apply.data[0].intervention_id"
        :experience="value.apply.data[0].experience"
        :localisation="getCity"
        :remuneration="value.apply.data[0].remuneration" />
      </div>

    <div v-if="informationsOpen"><span>
        <component-title-pencil :title="'Motivation'">
            <template v-slot:content>
                {{value.apply.data[0].why_me}}
            </template>
        </component-title-pencil>
        </span></div>
        
  </div>
</template>

<script>
import moment from "moment";
import componentTitlePencil from "../elementComponents/componentTitlePencil.vue";
import componentInformationsContactProfile from "../elementComponents/componentInformationsContactProfile.vue";
//import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiClose,
  mdiDotsHorizontal,
  mdiShareVariant,
  mdiAccountDetails,
} from "@mdi/js";

const getAge = (birthDate) =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export default {
  components: {
    //SvgIcon,
    componentInformationsContactProfile,
    componentTitlePencil
  },
  data() {
    return {
      close: mdiClose,
      menu: mdiDotsHorizontal,
      dropClose: false,
      isOpen: false,
      openCard: true,
      informationsOpen: true,
      cv: false,
      site: false,
      portfolio: false,
      dropDown: false,
      share: mdiShareVariant,
      details: mdiAccountDetails,
    };
  },
  props: ["value", "parentState"],
  methods: {
    testing() {
      console.log(this.open);
    },
    openCv() {
      this.informationsOpen = false;
      this.cv = true;
      this.site = false;
      this.portfolio = false
    },
    openSite() {
      this.informationsOpen = false;
      this.cv = false;
      this.site = true;
      this.portfolio = false
    },
    openInformations() {
      this.informationsOpen = true;
      this.cv = false;
      this.site = false;
      this.portfolio = false
    },
    openPortfolio() {
      this.informationsOpen = false;
      this.cv = false;
      this.site = false;
      this.portfolio = true
    },
    closeCards() {
      this.$emit("close");
    },
    closeCard() {
      this.isOpen = !this.isOpen;
    },
    format_date_year(value) {
      if (value) {
        return moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      if (value) {
        return moment(String(value)).format("MMMM");
      }
    },
    toggleOpeningState(state) {
      this.isOpen = state;
    },
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
     calculAge() {
      // function calcul age of candidate with birthdate
      return this.value.birth_at ? getAge(this.value.birth_at) + " ans" : "NC";
    },
    upperCaseRemuneration(){
      
      this.value.apply.remuneration.toUpperCase();
    }
  },
  computed: {
    getCity() {
      return this.value.city ?? "NC"
    }},
  watch: {
    parentState(newValue) {
      this.isOpen = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-details-card-candidate{
    overflow-y: scroll;
    max-height: 75vh;
}

.main-div-details-card-candidate::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-details-card-candidate::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-details-card-candidate::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #585a5c;
}


.item-menu {
  margin: 2rem 1rem 0rem 0.2rem;
  color: black;
  font-weight: bold;
  background-color: #A7D5B0;
  padding: 0.5rem;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
}

.enumeration-menu {
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
.menu-choice-candidate {
  font-size: 16px;
  background-color: #D3EAD7;
  position: sticky;
}

.itemSelected {
  color: $color-primary;
  background-color: white;
  padding: 0.5rem;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: -1px;
}


</style>
