<!--Component of dropdown object -->
<template>
  <div class="filter-main-component" v-if="qualified != 'Qualifié'" v-bind:class="{ validate: checking }">

    <button
      class="button-filter"
      v-if="type === 'list'"
      @click="(checking = !checking), onClickButton()"
      v-bind:class="{ checkClose: !checking, checkOpen: checking }"
    >
      <svg-icon
        class="icon-filter"
        type="mdi"
        :path="check"
        aria-hidden="true"
      />
    </button>

    <div class="value-input" v-else-if="type === 'range'">

      <input class="input" type="number" v-model="min" placeholder="minimum" />
      <input class="input" type="number" v-model="max" placeholder="maximum" />

      

    </div>

    <slot name="content-filters">
      <slot name="content-filters-sup"> </slot>
      <slot class="filter-name" name="content-filter" />
    </slot>
  </div>

  <div class="filter-main-component" v-if="qualified == 'Qualifié'" v-bind:class="{ validate: !checking }">

    <button
      class="button-filter"
      v-if="type === 'list'"
      @click="(checking = !checking), onClickButton()"
      v-bind:class="{ checkClose: checking, checkOpen: !checking }"
    >
      <svg-icon
        class="icon-filter"
        type="mdi"
        :path="check"
        aria-hidden="true"
      />
    </button>

    <div class="value-input" v-else-if="type === 'range'">

      <input class="input" type="number" v-model="min" placeholder="minimum" />
      <input class="input" type="number" v-model="max" placeholder="maximum" />

    </div>

    <slot name="content-filters">
      <slot name="content-filters-sup"> </slot>
      <slot class="filter-name" name="content-filter" />
    </slot>
  </div>
</template>

<script>
//import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { mapActions } from "vuex";
import _ from 'lodash';

export default {
  components: {
    SvgIcon,
  },
  props: ["value", "toto", "values", "type", "qualified"],
  data() {
    return {
      chevronClose: mdiChevronDown,
      chevronOpen: mdiChevronUp,
      dropClose: false,
      check: mdiCheck,
      checking: false,
      min: null,
      max: null,

    };
  },
  computed: {
    range(){
      return [this.min, this.max];
    },
    range2(){
      return [this.min, this.max]
    },
  /*  tata(){
      return 
    }*/
  },
  watch: {
    min: _.debounce(function debounceRead() {
          this.updateFilter({key: this.toto, value: this.range});
          return;
       
  }, 3000),
  max: _.debounce(function debounceRead() {
       this.updateFilter({key: this.toto, value: this.range2}) 
       return;
  }, 3000)

    },
  methods: {
    toogle() {
      // toogle open & close dropdown
      this.dropClose = !this.dropClose;
    },
    
    onClickButton() {
      this.updateFilter({ key: this.toto, value: this.value });
      return;
    },
    ...mapActions(["updateFilter"]),

  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

.filter-main-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-family: $font-primary;
  color: black;
  font-size: 14px;
}
.button-filter {
  cursor: pointer;
  max-height: 1rem;
  max-width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid $color-primary;
  border-radius: 4px;
}


.value-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem;
  input {
    width: 100%;
    margin-left: auto;
    border-radius: 4px;
    margin: 0.2rem 0rem;
    border: 1px solid $color-primary;
  }
}
.icon-filter {
  min-height: 0.5rem;
  min-width: 0.5rem;
  max-height: 1rem;
  max-width: 1rem;
  color: white;
}

.checkClose {
  background-color: white;
}

.checkOpen {
  background-color: $color-primary;
}

.validate {
  background-color: #d3ead7;
}
</style>
