<template>
  <div  @click="$emit('close')" class="dropdown-menu" id="dropdown-menu2" role="menu">
    <div class="dropdown-content">
      <tabs :tabs="['Tout', 'Candidats', 'Documents']" @onTab="(el) => tabActive = el"/>
      <div class="dropdown-item">
        <media-object v-for="(el, index) in values" :key="index" :first_name="el.first_name"  :last_name="el.last_name" :id_profile="el.id" :action="el.action || null">
          <router-link
                  :to="{
                    name: 'Profil',
                    params: { id: el.id },
                  }"
                  >
                
          <div class="div-date-created-at" v-for="apply in el.applies" :key="apply.id">
           Candidaté le {{ dateTime(apply.created_at) }}
        </div>
          </router-link>
        </media-object>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/navigation/Tabs";
import MediaObject from "@/components/MediaObject";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ['values'],
  data () {
    return {
      searchQuery: null,
      tabActive: 0,
      results: [
        {
          title: 'Fabio ROCCO',
          description: 'Candidaté il y a 3 mois',
          action: 'toto'
        },
        {
          title: 'Fabio ROCCO',
          description: 'Candidaté il y a 3 mois'
        },
        {
          title: 'Fabio ROCCO',
          description: 'Candidaté il y a 3 mois'
        },
        {
          title: 'Fabio ROCCO',
          description: 'Candidaté il y a 3 mois'
        },
        {
          title: 'Fabio ROCCO',
          description: 'Candidaté il y a 3 mois'
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch('getCandidatesList', { searchQuery: this.searchQuery});
  },
  computed: {
    ...mapGetters({
      candidates: 'candidatesList'
    })
  },
  methods: {
    dateTime(value) {
      return moment(value).format('LL');
    },
  },
  watch: {
    searchQuery: function(){
      this.$store.dispatch('getCandidatesList', { searchQuery: this.searchQuery});
    }
  },
  components: {
    Tabs,
    MediaObject
  }
}
</script>

<style lang="scss" scoped>
a.router-link-active.router-link-exact-active {
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
  }

  .div-date-created-at{
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }

  .router-link-exact-active {
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
  }
.dropdown-menu {
  position: absolute;
  top: 4rem;
  width: 100%;
  height: 20rem;
  display: block;
  padding-top: 0;
  .dropdown-content {
    border-radius: inherit;
    div.dropdown-item {
      padding: 0;
    }
  }
}
</style>