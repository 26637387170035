<template>
  <div class="main-div">
    <i class="icon-svg">
      <slot />
    </i>

    <a
      ><span class="content-title"
        >{{ link }} <slot name="content-title" /> </span
    ></a>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/variables";

.main-div {
  display: flex;
  align-items: center;
  width: 70%;
}

.content-title {
  margin-left: 1rem;
  color: white;
  font-family: $font-primary-light;
  font-size: $font-small;
}
</style>
