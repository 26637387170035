<!-- template documents in bunk -->
<template>
  <div v-if="documentSide !== true" class="shared-document">
    <a
      ><span class="extension-doc"><slot name="acronyme-doc" /> </span
    ></a>
  </div>

  <div v-if="documentSide == true" class="shared-document-side">
  <a><span class="extension-doc"><slot name="acronyme-doc" /></span>
      <span class="name-doc"><slot name="name-doc" /> </span>
      <svg-icon
      class="svg-link"
            type="mdi"
            :path="link"
            aria-hidden="true"
          /></a>
  </div>

</template>
<script>
import { mdiLink } from '@mdi/js';
import SvgIcon from "@jamescoyle/vue-icon";

export default ({
  components:{
      SvgIcon,
  },
  data(){
    return {
      link: mdiLink
    }
  },
 props: {
    documentSide: Boolean,
  },
})
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

.shared-document {
  width: 3.5rem;
  height: 3.5rem;
  margin: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shared-document-side{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  a{
    display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.5rem;
  align-items: center;
  .name-doc{
    color: black;
  }
  .svg-link{
    color: #898D90;
  }
  }
  
}
.extension-doc {
  font-family: $font-primary-semi-bold;
  font-size: $font-high;
  color: $color-primary;
}
</style>
