import axios from "axios";

const defaultState = () => {
    return {
        searchInput: "",
        results: [],
        loading: true,
        status: true,
        errors: {}
    };
};

const state = defaultState();

// getters
const getters = {
    searchInput: state => state.searchInput,
    getCandidatesList: state => state.results,
    loading: state => state.loading,
    status: state => state.status,
    errors: state => state.errors
};

// actions
const actions = {
    resetSearchInput({ commit }) {
        commit("resetState");
    },
    updateSearchInput({ commit }, value) {
        commit('updateSearchInput', value)
        console.log('value : ' + value)
        console.log(commit)
    },
    getCandidatesList({commit}, payload){
        let url = `/get-candidatesdata`;

        axios.post(url, payload)
            .then(res => {
                commit('setCandidatesListdata', res.data)
            })
      } 
};

// mutations
const mutations = {
    setCandidatesListdata(state, results){
        state.results = results;
      },
    /**
     * Set the state to loading
     * @param state
     */
    load(state) {
        state.loading = true;
    },
    /**
     * Reset the state with the default state specified
     * @param state
     */
    resetState(state) {
        Object.assign(state, defaultState());
    },
    /**
     * Update the state's search input column
     * @param state
     * @param value
     */
    updateSearchInput(state, value) {
        state.searchInput = value
    },
    /**
     * Set the state to not loading
     * @param state
     */
    endLoad(state) {
        state.loading = false;
    },
   
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
