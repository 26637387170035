<!--Component of details object -->
<template>

    <span>Commentaires</span>

      <div class="new-comment-div">
      <label>Nouveau commentaire</label>
<input @keypress.enter="submitComment(getCandidate.data.id)" type="text" v-model="comment" placeholder="Laisser un commentaire" />

</div>
<div class="div-comment">
    <div class="main-div-comment" v-for="note in getCandidate.data.note.slice().reverse()" :key="note.id">
    
<div>
  <div class="first-main-div-follow-part">
    <div class="main-div-follow-part">
    <div class="first-div-note">
      <div class="div-note-svg">
      <svg-icon
      class="text-note"
      type="mdi"
      :path="commentSvg"
      aria-hidden="true"
    />
    </div>
    <div class="main-div-note">
      <span class="date-note">Le {{ dateTime(note.created_at) }}</span>
    </div>
    </div>   
    </div>

<span>  {{ note.comment.replace(/^"(.*)"$/, '$1') }}</span>

</div>

</div>
</div>
</div>


</template>

<script>
import moment from "moment";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiText, mdiPencil} from '@mdi/js';
import {mapGetters} from "vuex";
import axios from 'axios';

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      dropClose: false,
      comment: null,
      apply_id: null, 
      isOpen: false,
      openCard: true,
      commentSvg: mdiText,
      pencil: mdiPencil
    };
  },
  props: ['value'],
  methods: {
    dateTime(value) {
      return moment(value).format('LL');
    },
    submitComment(candidate_id){

      this.candidate_id = candidate_id;
    const formData = new FormData()
    formData.append('candidate_id', JSON.stringify(this.candidate_id))
    formData.append('comment', JSON.stringify(this.comment))
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id)
    axios.post( `${process.env.VUE_APP_API_CANDIDATES}/notes/`,
      formData,
      {
        
        headers: {
            "Access-Control-Allow-Origin": '*' 
        },
      }
    ).then(function(){
      response => {
         response.data
          }
         // this.$store.dispatch("fetchOneCandidate", this.$route.params.id)
    })
    .catch(function(){
      error => console.log(error)
    });
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id)
    },
    format_date_year(value) {
      if (value) {
        return moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      if (value) {
        return moment(String(value)).format("MMMM");
      }
    },
  },
  created() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  watch: {
    month () {
      this.$store.dispatch("fetchOneCandidate", this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters([
      'getCandidate'
    ]),}
}
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.div-note-svg{
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 0.125rem;
  display: flex;
  flex-direction: column;
  max-height: 20px;
  max-width: 20px;
  svg{
    width: 16px;
    height: 16px;
  }

}


  .new-comment-div{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin:1rem 0.5rem;
    input{
      padding: 0.25rem;
      border: 1Px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 4px;
      outline: none;
    }
  .div-comment{
    width: 100%;
  }

}
.div-comment{
  overflow-y: scroll;
  max-height: 55vh;
  padding: 0.5rem;
}

.first-div-note{
    display: flex;
}
.main-div-note{
    margin-left: 2rem;
.div-note-user{
    display: flex;
    align-items: center;
    span{
        font-weight: bold;
    }
}
.date-note{
    color: #A1A4A7;
    font-size: 10px;
    font-weight: lighter;
}
}
.first-main-div-follow-part{
  margin: 0.5rem 0.25rem;
  font-size: 12px;
  font-family: $font-primary;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem;
}
.pencil-modification{
  cursor: pointer;
}
.main-div-follow-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  .main-div-rdv{  
 h1{
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  span{
    font-size: 12px;
    color: #A1A4A7;
  }
  }
}

.user-logo {
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}


.div-comment::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}
.div-comment::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.div-comment::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #585a5c;
}



</style>
