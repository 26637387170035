<template>
  <div class="main-div-card-candidatheque">
    <button
      class="button-details"
      @click="toggleAllOpeningState"
      v-bind:class="{ detailsClose: !allOpening, detailsOpen: allOpening }"
    >
      Vue détaillée
    </button>

     <div class="main-div-applies">
      <div
        class="candidates-div"
        v-for="(candidate, index) in this.$store.state.list.data"
        :key="index"
      >
        <card-candidate :value="candidate"  :parentState="allOpening"/>
      </div>
    </div>
  </div>
</template>

<script>
import CardCandidate from "../views/CardCandidate.vue";

export default {
  name: "candidates",
  components: {
    CardCandidate,
  },
  props: ["value"],
  data() {
    return {
      candidates: [],
      allOpening: false,
      openDetails: false,
    };
  },
  methods: {
    getFirstLetters(str) {
      const strLetters = str
        .split(" ")
        .map((word) => word[0])
        .join("");

      return strLetters;
    },
    onClickChild(value) {
      console.log(value); // someValue
    },
    showMore() {
      this.showMoreFilter = !this.showMoreFilter;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
  },
  created(){
        this.$store.dispatch("fetchCandidates2");
    this.candidates = this.$store.state.list.data;
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.main-div-card-candidatheque {
  grid-column: 3 / span 10;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 60px;
}

.button-details {
  margin-left: auto;
  padding: 0.5rem 1rem;
  margin-right: 24px;
  font-family: $font-primary;
  border-radius: 4px;
  cursor: pointer;
}
.detailsOpen {
  color: $color-primary;
  border: 1px solid $color-primary;
  font-weight: bold;
  background-color: white;
}
.detailsClose {
  color: black;
  border: 1px solid #e7e8e8;
}
.main-div-applies {
  @include grid;
  display: grid;
  margin: 1rem 2rem;
  padding: 2rem 0.5rem;
  overflow-y: scroll;
  max-height: 65vh;
}
.main-div-applies::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.candidates-div {
  grid-column: span 3;
}
.main-div-applies::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #949494;
}
</style>
