<template>
  <div class="appbar-search" :class="{'is-active': searchInputModel}" >
    <div class="control has-icons-left has-icons-right">
      <input  @focus="focusOpen"  ref="globalSearch" class="input is-small" placeholder="Rechercher dans l’application" type="text"
             v-model="searchQuery">
      <span class="icon is-small is-left">
        <searchIcon/>
      </span>
      <div class="icon is-small is-right">
        <template v-if="!searchInputModel">
          <span class="shortcut">
            <returnIcon/>
          </span>
          Accès rapide
        </template>
        <a class="is-underlined has-text-black" @click="resetSearchInput" v-else>Réinitialiser la recherche</a>
      </div>
    </div>
  </div>
  <app-bar-search-results :values="this.$store.state.results" @focusout="focusClose"   v-if="searchQuery && focus" />
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import ReturnIcon from "@/components/icons/ReturnIcon.vue";
import AppBarSearchResults from "@/components/navigation/AppBarSearchResults";
import { mapGetters, mapActions } from "vuex";

export default {
  data(){
    return {
      focus:true,
      searchQuery: null,
      candidates: null
    }
  },
  computed: {
    ...mapGetters('search', ['searchInput'],{
      candidates: 'candidatesList'
    } ),
    searchInputModel: {
      get () {
        return this.searchInput
      },
      set (value) {
        this.updateSearchInput(value)
      }
    }
  },
  methods: {
    ...mapActions('search', ['updateSearchInput', 'resetSearchInput']),

    focusClose () {
      this.focus = false;
    },
    focusOpen () {
      this.focus = true;
    }
  },
  mounted() {
    this.$store.dispatch('getCandidatesList', { searchQuery: this.searchQuery});
  }, 
  watch: {
    searchQuery: function(){
      this.$store.dispatch('getCandidatesList', { searchQuery: this.searchQuery});

    }
  },
  components: {
    SearchIcon,
    ReturnIcon,
    AppBarSearchResults
  },
}
</script>

<style lang="scss" scoped>
.appbar-search {
  display: flex;
  flex-grow: 1;
  &.is-active {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  }
  div.control {
    width: 100%;
    padding-left: 1rem;
    padding-right: 2rem;
    input {
      height: 100%;
      border: inherit;
      padding-left: 2rem;
      box-shadow: inherit;
      padding-right: 9rem;
    }
    .icon.is-small {
      font-size: 1rem;
      width: 1rem;
      height: 100%;
      &.is-left {
        left: 1rem;
      }
    }
    div.icon.is-right {
      color: rgba(0, 0, 0, 60%);
      font-size: .75rem;
      width: auto;
      padding-right: 2rem;
      pointer-events: inherit;

      .shortcut {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        width: 2rem;
        height: 2rem;

        background: #FFFFFF;

        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-size: .875rem;
        font-family: 'poppins-medium', sans-serif;

        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: .5rem;
      }
    }
  }
}
</style>