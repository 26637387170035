<template>
  <div
    class="second-div-applies"
    @dblclick="openCardSide"
  >
   <div class="evaluation-div"> 
    <span class="title-span">Informations de candidature</span>

     </div>
    
     <div class="main-div-informations">
        <div class="main-div-left">
        <div class="contact-div-info">
    <span class="svg-icon"> <svg-icon class="svg" type="mdi" :path="mdiLinkedin" aria-hidden="true" /> </span>

        <informations-component-column
            :elementTitle="'Linkedin'"
            :elementContent="linkedin"
          />
        </div>    
        <div class="contact-div-info">
        <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="birthday" aria-hidden="true" /></span>
          <informations-component-column
            :elementTitle="'Age'"
            :elementContent="age">
            <template v-slot:content1>
               <span class="date-birth"> {{format_date(birth_at)}} </span>
            </template>
          </informations-component-column>
        </div>
        <div class="contact-div-info">
    <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="interventionSvg" aria-hidden="true" /></span>
          <informations-component-column
            :elementTitle="'Statut'">
            <template v-if="statut == 1" v-slot:content1
              >Freelance</template
            >
            <template v-slot:content2 v-if="statut == 2">
              Salariat
            </template>
            <template v-slot:content3 v-if="statut == 3">
              Alternance</template>
              <template v-slot:content4 v-if="statut == 4">
              Stage</template
            >
              
          </informations-component-column>
        </div>
        </div>

        <div class="main-div-right">
        <div class="contact-div-info">
    <span class="svg-icon"> <svg-icon class="svg" type="mdi" :path="localisationMdi" aria-hidden="true" /> </span>

        <informations-component-column
            :elementTitle="'Localisation'"
            :elementContent="localisation">
            <template v-slot:content1>
               <span class="date-birth"> {{address}}  {{postal_code}}</span>
            </template>
        </informations-component-column>
        </div>    
        <div class="contact-div-info">
        <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="work" aria-hidden="true" /></span>
          <informations-component-column
            :elementTitle="'Expérience'"
            :elementContent="experience"
          >
            <template v-slot:content1> Ans</template>
          </informations-component-column>
        </div>
        <div class="contact-div-info">
    <span class="svg-icon"><svg-icon class="svg" type="mdi" :path="euro" aria-hidden="true" /></span>
          <informations-component-column
            :elementTitle="'Rémunération'"
            :elementContent="remuneration">
            <template v-if=" statut == 1" v-slot:content1>
              €/jour
            </template>
            <template v-slot:content2 v-if="statut == 3">
              €/an
            </template>
            <template v-slot:content3 v-if="statut == 2">
              €/an
            </template>
          </informations-component-column>
        </div>
        </div>

     </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import informationsComponentColumn from "../elementComponents/informationsComponentColumn.vue";
import {
  mdiClose,
  mdiEmailOutline,
  mdiCalendarWeek, 
  mdiPencil,
  mdiPhone,
  mdiLinkedin,
  mdiCakeVariant,
  mdiBallotOutline,
  mdiMapMarker,
  mdiBriefcase,
  mdiCurrencyEur 

} from "@mdi/js";

const getAge = (birthDate) =>
  // create function age with birthday 
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export default {
  components: {
    SvgIcon,
    informationsComponentColumn

  },
  name: "candidates",
  created() {
    // dispatch store One candidate
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  data() {
    return {
      allOpening: false,
      close: mdiClose,
      dropClose: false,
      isOpen: false,
      openCard: true,
      informationsOpen: true,
      follow: false,
      files: false,
      dropDown: false,
      mail: mdiEmailOutline,
      calendar: mdiCalendarWeek,
      pencil: mdiPencil,
      phoneMdi: mdiPhone,
      mdiLinkedin: mdiLinkedin,
      birthday: mdiCakeVariant,
      interventionSvg: mdiBallotOutline,
      localisationMdi: mdiMapMarker,
      work: mdiBriefcase,
      euro: mdiCurrencyEur
    };
  },
  props: {
    email: String,
    linkedin: String,
    phone: String,
    statut: [String, Number],
    age: String,
    experience: [String, Number],
    localisation: String,
    remuneration: [String, Number],
    birth_at: String,
    address: String, 
    postal_code: String
  },
  methods: {
    dropDownButton() {
      // function to open & close dropdown informations
      this.dropDown = !this.dropDown;
    },
    toggleAllOpeningState() {
      this.allOpening = !this.allOpening;
    },
    format_date_year(value) {
      //function to pass date and display year
      if (value) {
        return this.$moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      //function to pass date and display month
      if (value) {
        return this.$moment(String(value)).format("MMMM");
      }
    },
    format_date(value) {
      //function to pass date and display 
      if (value) {
        return this.$moment(String(value)).format("DD MMMM YYYY");
      }
    },
     calculAge() {
      // function calcul age of candidate with birthdate
      return getAge(this.value.birth_at);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.evaluation-div{
  grid-column: 1/ span 12;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.5rem;
  border-bottom: 0.1rem solid #E7E8E8
}

.date-birth{
    font-size: 12px;
    color: #a1a4a7;
}

.title-span{
    font-size: 14px;
    font-weight: bold;
}
.main-div-informations{
    grid-column: 1/span 12;
    display: flex;
    margin: 1rem 2rem;
}

.main-div-left,
.main-div-right{
    width: 50%;
}


.contact-div-info{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.svg-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3rem;
    max-height: 3rem;
    padding: 0.7rem;
    background-color: #E7E8E8;
    border-radius: 50%;
    margin: 1rem 0.5rem;
}

.svg{
    max-width: 1rem;
    max-height: 1rem;
}


.second-div-applies {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 2rem 0.2rem;
  font-family: $font-primary;
  color: black;
  font-size: 12px;
  height: fit-content;
  grid-column: span 3;
  margin-left: 1rem;
  @include grid;
  display: grid;
  .fav_item {
    display: flex;
    align-items: center;
    grid-column: 1 / span 12;
    position: relative;
    top: 10px;
  }

  .position-title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 1rem;
    color: black;
    width: 25%;
  }
  .intervention-title {
    font-size: 10px;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
  }
  div {
    .name-intervention-candidate {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 0rem 0.2rem;
      span {
        text-transform: uppercase;
      }
    }
  }
}
.details {
  background-color: #a8d0b0;
}
</style>
