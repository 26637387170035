<!--Component of details object -->
<template>
  <div class="main-div-details-card-candidate">

    <note-card />
        
  </div>
</template>

<script>
import noteCard from "../elementComponents/noteCard.vue";



export default {
  components: {
    noteCard,

  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-details-card-candidate{
    margin: 2rem;
}
</style>
