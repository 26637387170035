import { createStore } from "vuex";
import axios from "axios";
import search from "@/store/modules/search";
import identity from "@/store/modules/identity";

export default createStore({
  state: {
    candidate: [],
    results: [],
    nextCandidate: [],
    prevCandidate: [],
    list:[],
    listBannette:[],
    filter: {
      status: [2]
    },
    jobs: [],
    document:[],
    interventions: []
  },
  getters: {
    getCandidatesList: state => state.results,
    getCandidate: (state) => state.candidate,
    getNextCandidate: (state) => state.nextCandidate,
    getPrevCandidate: (state) => state.prevCandidate,
    getList: (state) => state.list.data,
    getListBannette: (state) => state.listBannette.data,
    getFilters: (state) => state.filter,
    getJobs: (state) => state.jobs,
    getDocuments: (state) => state.document,
    getInterventions: (state) => state.interventions
  },
  mutations: {
    setCandidatesListdata(state, results){
      state.results = results;
    },
    SET_LIST(state, list){
      state.list = list;
    },
    SET_JOB(state, jobs){
      state.jobs = jobs;
    },
    SET_INTERVENTION(state, interventions){
      state.interventions = interventions;
    },
    SET_CANDIDATE(state, candidate) {
      state.candidate = candidate;
    },
    SET_NEXTCANDIDATE(state, nextCandidate) {
      state.nextCandidate = nextCandidate;
    },
    SET_PREVCANDIDATE(state, prevCandidate) {
      state.prevCandidate = prevCandidate;
    },
    SET_LIST_BANNETTE(state, listBannette){
      state.listBannette = listBannette
    },
    SET_LIST_DOCUMENT(state, document){
      state.document = document
    },
    UPDATE_FILTER(state, {key, value}){
      if(state.filter[key]) {
        if(state.filter[key].includes(value)){
          state.filter[key].splice(state.filter[key].indexOf(value), 1);
        /*  if(state.filter[key].length === 0){
             delete state.filter[key]
          }*/
          return;
        } 
        if(Array.isArray(value)){
          if(state.filter[key].some(el => value.includes(el))){
            value.forEach(element => {
              console.log(state.filter);
              console.log(element)

            //  state.filter[key].splice(state.filter[key].indexOf(element), 2);
            }); 
            return;
          }
          state.filter[key] = value
        } else {
          state.filter[key].push(value)
        }
      } else {
       
        state.filter[key] = Array.isArray(value) ? value : [value]
        
      }
    },
  },
  actions: {
    getCandidatesList({commit}, payload){
      let url = `${process.env.VUE_APP_API_CANDIDATES}/get-candidatesdata`;

      axios.post(url, payload)
          .then(res => {
              commit('setCandidatesListdata', res.data)
          })
    } ,
    async fetchJobs({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_API_CANDIDATES}/jobs`);
        commit("SET_JOB", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInterventions({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_API_CANDIDATES}/interventions`);
        commit("SET_INTERVENTION", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchOneCandidate({ commit }, id) {
      try {
        const data = await axios.get(
          `${process.env.VUE_APP_API_CANDIDATES}/candidates/${id}`, {
            withCredentials: true
          }
        );
        commit("SET_CANDIDATE", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchNextCandidate({ commit }, id) {
      try {
        let nextId = parseInt(id);
        nextId += 1;
        const data = await axios.get(
          `${process.env.VUE_APP_API_CANDIDATES}/candidates/${nextId}`
        );
        commit("SET_CANDIDATE", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPrevCandidate({ commit }, id) {
      try {
        let prevId = parseInt(id);
        prevId -= 1;
        const data = await axios.get(
          `${process.env.VUE_APP_API_CANDIDATES}/candidates/${prevId}`
        );
        commit("SET_PREVCANDIDATE", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCandidatesList({ commit }) {
      try {

        const data = await axios.get(`${process.env.VUE_APP_API_CANDIDATES}/candidates`, {
          withCredentials: true,
          params: {
            filter: {status: [1]}
          },
        });
        commit("SET_LIST_BANNETTE", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDocumentsList({ commit }) {
      try {

        const data = await axios.get(`${process.env.VUE_APP_API_CANDIDATES}/documents`, {
          withCredentials: true,
          content__extracted_content__icontains: "Padmé"
        });
        commit("SET_LIST_DOCUMENT", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCandidates2({ commit, getters }) {
      
      try {
        const data = await axios.get(`${process.env.VUE_APP_API_CANDIDATES}/candidates`, {
          withCredentials: true,
          params: {
            filter:  getters.getFilters 
          },
        });
        commit("SET_LIST", data.data);
      } catch (error) {
        console.log(error);
      }
    },
     updateFilter({commit}, payload){
      commit('UPDATE_FILTER', payload);
      this.dispatch('fetchCandidates2');
      this.dispatch('fetchCandidatesList');
    } 
  },
  modules: {
    search,
   identity
  },
});

