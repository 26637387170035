<!--Component of details object -->
<template>
  <div class="main-div-details-card-candidate">
    <div>
      <files-side-details :value="value" />
    </div>
    <div class="div-dropzone">
    <!--  <file :value="value.document" @input="fileUpdate"/> -->
     <!-- <drop-zone @drop.prevent="drop" /> -->
    </div>

    <div
      class="modal"
      v-bind:class="{ 'is-active': isActive, closeModal: !isActive }"
      v-if="dropzoneFile != ''"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <h1>Ajout d'un fichier</h1>
        <span>Fichier déposé <span class="symbol">*</span></span>
        <div>
          <svg-icon
            class="upload"
            type="mdi"
            :path="upload"
            aria-hidden="true"
          />
          <span>RE:</span>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="toogleActive"
      ></button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
//import File from '../File.vue';
//import dropZone from "../elementComponents/dropZone.vue";
import filesSideDetails from "../elementComponents/filesSideDetails.vue";
import { ref } from "vue";

import { mdiTrayArrowUp } from "@mdi/js";

export default {
  components: {
    filesSideDetails,
    //File,
    //dropZone,
    SvgIcon,
  },
  setup() {
    let dropzoneFile = ref("");

    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    return { dropzoneFile, drop };
  },
  data() {
    return {
      isActive: true,
      upload: mdiTrayArrowUp,
      candidate: {
        document: []
      }
    };
  },
  props: ["value", "parentState"],
  methods: {
    toogleActive() {
      this.isActive = !this.isActive;
    },
    fileUpdate(file) {
    this.candidate.document = file
  },
  },
  watch: {
    parentState(newValue) {
      this.isOpen = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  font-family: $font-primary;
  h1 {
    font-size: 24px;
    color: black;
  }
  div {
    border: 1px solid #e7e8e8;
    border-radius: 4px;
    margin: 0.5rem;
    display: flex;
    align-items: center;
  }
  .symbol {
    color: red;
  }
  .upload {
    background-color: #e7e8e8;
    min-height: 50px;
  }
}

.div-dropzone {
  grid-column: 6 / span 6;
}
.main-div-details-card-candidate {
  max-height: 75vh;
  display: flex;
  margin: 2rem;
  @include grid;
  display: grid;
}
</style>
