<!--Component of details object -->
<template>
  <div class="first-main-div-follow-part">
    <div class="main-div-follow-part">
      <div class="div-created-at">
      <span>MAR</span>
      <span ><strong>18</strong></span>
    </div>

    <div class="main-div-rdv">
      <h1>Entretien téléphonique</h1>
      <span>11:00-12:00</span>
    </div>

    </div>

    <div class="second-div-follow-part">
      <span class="title-interviewer">Interviewers</span>

      <div class="user-part-follow">
           <img class="user-logo" src="../../assets/icons/padme_user.svg" />
           <span>Padme</span>
      </div>

      <button>Ajouter un interviewer</button>

      <span class="evaluation-span">Evaluations</span>

    </div>
  </div>
</template>

<script>
import moment from "moment";
//import SvgIcon from "@jamescoyle/vue-icon";
 import { mdiPencil } from '@mdi/js';

export default {
  components: {
   // SvgIcon,
  },
  data() {
    return {
      dropClose: false,
      isOpen: false,
      openCard: true,
      pencil: mdiPencil
    };
  },
  props: ['value'],
  methods: {
    format_date_year(value) {
      if (value) {
        return moment(String(value)).format("YYYY");
      }
    },
    format_date_month(value) {
      if (value) {
        return moment(String(value)).format("MMMM");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.first-main-div-follow-part{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  font-family: $font-primary;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem;
}
.pencil-modification{
  cursor: pointer;
}
.main-div-follow-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .main-div-rdv{  
 h1{
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  span{
    font-size: 12px;
    color: #A1A4A7;
  }
  }
}

.div-created-at {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: $font-primary;
  color: black;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: fit-content;
}

.second-div-follow-part{
  display: flex;
  flex-direction: column;
  font-family: $font-primary;
  margin: 0rem 0.5rem;
  .title-interviewer,
  .evaluation-span{
    font-size: 12px;
    color: #A1A4A7;
  }
  .title-interviewer{
    margin: 1rem 0rem;
  }
  .user-part-follow{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  button{
    display: flex;
    border: none;
    text-decoration: none;
    background-color: white;
    text-decoration: underline;
    color: #3273DC;
    font-size: 10px;
    font-family: $font-primary;
    margin: 1rem 0rem;
    cursor: pointer;
  }
}

.user-logo {
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

</style>
