<!-- GREEN conseil icon svg-->
<template>
   <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.404 1.8764C16.1246 1.8764 2.92639 15.0769 2.92639 31.3585C2.92639 47.6415 16.1245 60.8413 32.404 60.8413C48.6835 60.8413 61.8809 47.6415 61.8809 31.3585C61.8809 15.0769 48.6835 1.8764 32.404 1.8764ZM43.5713 20.9873C40.1008 17.5164 35.3209 15.9339 30.4504 16.6738C23.5736 17.7159 18.2196 23.4726 17.6408 30.4049C16.8892 39.4213 24.0008 46.9247 32.8005 46.9247C35.5999 46.9247 38.2251 46.1683 40.4791 44.8457C40.4791 44.8457 40.5458 47.8417 40.2737 48.5025C37.9885 49.5229 35.4573 50.0907 32.8005 50.0907C22.3009 50.0907 13.7871 41.2216 14.4608 30.5238C15.0396 21.3374 22.5691 13.9086 31.7579 13.4101C37.0481 13.1236 42.049 15.0234 45.7692 18.7423L43.5713 20.9873ZM26.5489 37.9767C28.0409 39.5273 30.4065 39.8818 32.5689 39.9236L32.5086 42.8963C29.5732 42.8052 26.4711 42.1662 24.4147 40.0309C22.3629 37.8965 21.2654 35.1031 21.3203 32.1986C21.3792 29.2275 22.5807 26.4767 24.7136 24.4239C26.8138 22.4025 29.6053 21.308 32.578 21.3643L32.4866 24.3022C30.3249 24.261 28.3195 25.0689 26.7693 26.5611C25.1866 28.0833 24.301 30.0589 24.2591 32.2216C24.2179 34.3822 25.0563 36.4263 26.5489 37.9767Z" fill="white"/>
<path d="M34.7094 32.694H45.1335V45.2553L48.2087 43.0344V30.216H34.7094V32.694Z" fill="#25963A"/>
<path d="M64 2.24865C64 3.49078 62.9927 4.49798 61.7513 4.49798C60.5084 4.49798 59.5027 3.49078 59.5027 2.24865C59.5027 1.00729 60.5084 0 61.7513 0C62.9927 0 64 1.00729 64 2.24865Z" fill="#25963A"/>
<path d="M6.25666 60.8704C6.25666 62.5975 4.85557 64 3.12765 64C1.39973 64 0 62.5975 0 60.8704C0 59.1421 1.39963 57.7396 3.12765 57.7396C4.85567 57.7396 6.25666 59.1421 6.25666 60.8704Z" fill="white"/>
</svg> 
</template>


