<!--Component of details object -->
<template>
  <div class="main-div-note">
    <rdv-card :value="value" />
    <note-card :value="value" />

    <div class="note-history">
      <div class="div-note-svg">
        <svg-icon
          class="text-note"
          type="mdi"
          :path="mailCandidate"
          aria-hidden="true"
        />
      </div>

      <div class="second-div-history">
        <div class="mail-candidate">
          <img class="user-logo" src="../../assets/icons/padme_user.svg" />
          <span>Padme</span>
          <a>uploadé RE : Candidature GREEN Conseil</a>
        </div>
        <span class="date-note">Lundi 15 Novembre 2021</span>
      </div>
    </div>

    <div class="note-history">
      <div class="div-note-svg">
        <svg-icon
          class="text-note"
          type="mdi"
          :path="mail"
          aria-hidden="true"
        />
      </div>

      <div class="second-div-history">
        <div class="mail-candidate">
          <img class="user-logo" src="../../assets/icons/padme_user.svg" />
          <span>Padme </span>
          <span>Demande de rendez-vous</span>
        </div>
        <span class="date-note">Lundi 15 Novembre 2021</span>
      </div>
    </div>
  </div>
</template>

<script>

import rdvCard from "../elementComponents/rdvCard.vue";
import noteCard from "../elementComponents/noteCard.vue";
import { mdiEmailPlusOutline, mdiEmailOutline} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  components: {
    rdvCard,
    noteCard,
    SvgIcon,
  },
  data() {
    return {
      dropClose: false,
      isOpen: false,
      openCard: true,
      mailCandidate: mdiEmailPlusOutline,
      mail: mdiEmailOutline
    };
  },
  props: ["value"],
};
</script>
<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-note {
  overflow-y: scroll;
  max-height: 60vh;
}
.note-history{
  display: flex;
  align-items: center;
  margin: 2rem;
  font-size: 10px;
}

.div-note-svg{
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 40px;
  max-height: 40px;
}

.user-logo {
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.mail-candidate{
  display: flex;
  align-items: center;
  margin-left: 1rem;
  span{
    margin-right: 0.5rem;
  }
}

.date-note{
  margin-left: 3rem;
  color: #A1A4A7;
}


</style>
