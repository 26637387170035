<template>
  <div
    class="second-div-applies"
    @dblclick="openCardSide"
  >
    <div class="evaluation-div">
      <span class="title-span">{{ title }}</span>

    </div>
    <div class="slot-content">
      <slot name="content"> </slot>
    </div>
  </div>
</template>

<script>
//import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPencil } from "@mdi/js";

export default {
  components: {
   // SvgIcon,
  },
  name: "candidates",
  created() {
    this.$store.dispatch("fetchOneCandidate", this.$route.params.id);
  },
  data() {
    return {
      pencil: mdiPencil,
    };
  },
  props: {
    title: String,
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.slot-content {
  grid-column: 1 / span 12;
  margin: 1rem;
  font-size: 14px;
}

.title-span {
  font-weight: bold;
}
.evaluation-div {
  grid-column: 1 / span 12;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.5rem;
  border-bottom: 0.1rem solid #e7e8e8;
}

.second-div-applies {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 2rem 0.2rem;
  font-family: $font-primary;
  color: black;
  font-size: 12px;
  height: fit-content;
  grid-column: span 3;
  margin-left: 1rem;
  @include grid;
  display: grid;
  .fav_item {
    display: flex;
    align-items: center;
    grid-column: 1 / span 12;
    position: relative;
    top: 10px;
  }
}
.details {
  background-color: #a8d0b0;
}
</style>
